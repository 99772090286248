import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { WorkflowStepContext } from "../../../../../contexts";
import { NodeItem } from "./Components/nodeItem";
import { getWorkflowTables } from '../../../../../api/workflowTables';
import { Select } from "../../../../../components/Form/Select";
import { useTableFilterHelper } from '../../../../../hooks/useTableFilterHelper';
import { Checkbox } from '../../../../../components/Form/Checkbox';
import { appEventIconName } from '../../../../../utils/constants';

export const TableFieldMappingSetupActionStep = ({ workflowStepValues, setupActionStep, onStepValueChange, apiParameters, workflowStep }) => {
    const TABLES_INSERT_MULTIPLE_RECORDS = "tables-insert-multiple-records";

    const [data, setData] = useState([]);
    const [nodeValues, setNodeValues] = useState({});
    const [itemKeys, setItemKeys] = useState({});
    const stepContext = useContext(WorkflowStepContext);
    const tableFilterHelper = useTableFilterHelper();

    const handleNodeValueChange = (nodeId, value) => {
        onStepValueChange(setupActionStep.property, { ...workflowStepValues[setupActionStep.property], [nodeId]: value })
    }

    /*
    const initData = async () => {
        if (workflowStepValues?.tableId?.fields) {
            setData(workflowStepValues?.tableId?.fields);
            onStepValueChange('tableId', {
                name: workflowStepValues?.tableId?.name,
                value: workflowStepValues?.tableId?.value
            })
        }
    }
    */

    const loadData = async () => {
        if (apiParameters.length === 1) {
            const activeTables = await getWorkflowTables();
            var fields = activeTables.find(t => t.id == workflowStepValues.tableId.value)?.fields || [];
            setData(fields);
            setItemKeys({
                Status: "0_Status",
                ...fields.reduce((acc, field) => {
                    acc[field.id] = "0_" + field.id;
                    return acc;
                }, {})
            });
        }
    }

    const handleCheckboxChange = (checked, name) => {
        var newValues = { ...nodeValues };
        const id = name.slice(2);
        if (checked) {
            newValues[id] = nodeValues[id] || (id == "Status" ? "0" : "");
        }
        else {          
            delete newValues[id];
            setNodeValues(newValues)
            // Force the key to change so that the component re-renders
            setItemKeys({ ...itemKeys, [id]: `${itemKeys[id] && itemKeys[id].substring(0, 1) == "0" ? "1" : "0"}_${id}`});
        }
        onStepValueChange(setupActionStep.property, newValues);
    }

    useEffect(() => {
        const propValues = workflowStepValues[setupActionStep.property];

        if (propValues) {
            setNodeValues(propValues);
        }

    }, [workflowStepValues]);

    useEffect(() => {
        // initData();
        loadData();
    }, [apiParameters]);

    return (
        <>
            {data && data.length > 0 && (
                <>
                    <h4 className="mt-4 mb-2 font-semibold">Set values:</h4>
                    <div className="mt-4">
                        <div className="inline-block">
                            <Checkbox
                                id="c_status"
                                name="c_Status"
                                label="Status"
                                labelClassName="font-semibold"
                                labelPositionRight={true}
                                checked={nodeValues["Status"] != undefined}
                                onChange={handleCheckboxChange} 
                            />
                        </div>
                        <Select
                            id="status"
                            name="Status"
                            key={itemKeys["status"]} 
                            className="capitalize"
                            options={[
                                ...tableFilterHelper.statusOptions
                            ]}
                            onChange={value => {handleNodeValueChange("Status", value)}}
                            selectedValue={nodeValues["Status"] || "0"}
                        />
                    </div>
                </>
            )}
            {data?.map(field => (
                <div key={field.id} className="mt-4">
                    <div className="inline-block">
                        <Checkbox
                            id={"c_" + field.id}
                            name={"c_" + field.id}
                            label={field.name}
                            labelClassName="font-semibold"
                            labelPositionRight={true}
                            checked={nodeValues[field.id] != undefined}
                            onChange={handleCheckboxChange} 
                        />
                    </div>
                    <NodeItem
                        key={itemKeys[field.id]}
                        item={field}
                        nodeValue={nodeValues[field.id]}
                        handleNodeValueChange={handleNodeValueChange}
                        previousSteps={[
                            ...stepContext.previousSteps,
                            ...(workflowStep.selectedEvent.key == TABLES_INSERT_MULTIPLE_RECORDS ? [{
                                appEvent: {
                                    appIcon: appEventIconName.tablesIcon,
                                },
                                name: workflowStep.name,
                                id: workflowStep.id,
                                stepIdx: stepContext.stepIdx
                            }] : [])
                        ]}
                        stepIdx={stepContext.stepIdx}
                        workflowStep={workflowStep}
                        previousStepsValues={[
                            ...stepContext.previousStepsValues,
                            ...(workflowStep.selectedEvent.key == TABLES_INSERT_MULTIPLE_RECORDS ? [
                                Array.from({length: 20}).map((_, i) => ({
                                    "name": "Row column " + (i + 1).toString(),
                                    "isArray": false,
                                    "id": "row_column_" + (i + 1).toString(),
                                    "stepIdx": stepContext.stepIdx,
                                    "stepName": workflowStep.name,
                                    "stepId": workflowStep.id,
                                    "icon": appEventIconName.tablesIcon
                                })) 
                            ] : [])
                        ]}
                    />
                </div>
            ))}
        </>
    );

}