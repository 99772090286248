export default function LoopBranchStepLog({ stepLog }) {
    const numbderOfSuccessfulIterations = (stepLog.numberOfLoopIterations >= 0 && stepLog.numberOfFailedLoopIterations >= 0) ?
        stepLog.numberOfLoopIterations - stepLog.numberOfFailedLoopIterations : 0;
    return (
        <div>
            <div className="py-3">
                <span className="block text-base text-gray-700 ">Total Iterations: {stepLog.numberOfLoopIterations ?? 0}</span>
                <span className="block text-base text-gray-700 ">Total Failed Iterations: {stepLog.numberOfFailedLoopIterations ?? 0}</span>
                <span className="block text-base text-gray-700 ">Total Successful Iterations: {numbderOfSuccessfulIterations}</span>
            </div>
        </div>
    )
}