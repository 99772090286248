import React, { use, useContext, useEffect, useState } from 'react'
import { NodeItem } from './Components/nodeItem';
import { Select } from "../../../../../components/Form/Select";
import { DayPicker } from "react-day-picker";
import { WorkflowStepContext } from '../../../../../contexts';

export const ScheduleSetupActionStep = ({ workflowStepValues, setupActionStep, onStepValueChange, workflowStep }) => {

    const NOREPEAT = 'norepeat';
    const REPEAT_MINUTELY = 'minutely';
    const REPEAT_HOURLY = 'hourly';
    const REPEAT_DAILY = 'daily';
    const REPEAT_WEEKLY = 'weekly';
    const REPEAT_MONTHLY = 'monthly';
    const REPEAT_YEARLY = 'yearly';

    const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'September', 'October', 'November', 'December'];

    const repeatOptions = [
        {
            name: 'Don\'t repeat',
            value: NOREPEAT,
            unit: '',
            repeatEveryOptions: []
        },
        {
            name: 'Repeat minutely',
            value: REPEAT_MINUTELY,
            unit: 'minutes',
            repeatEveryOptions: [1,2,3,4,5,6,10,12,15,20,30]
        },
        {
            name: 'Repeat hourly',
            value: REPEAT_HOURLY,
            unit: 'hours',
            repeatEveryOptions: [1,2,3,4,6,8,12]
        },
        {
            name: 'Repeat daily',
            value: REPEAT_DAILY,
            unit: 'days',
            repeatEveryOptions: [1,2,3,4,5,6,7,8,9,10]
        },
        {
            name: 'Repeat weekly',
            value: REPEAT_WEEKLY,
            unit: 'weeks',
            repeatEveryOptions: [1,2,3,4,5,6,7,8,9,10]
        },
        {
            name: 'Repeat monthly',
            value: REPEAT_MONTHLY,
            unit: 'months',
            repeatEveryOptions: [1,2,3,4,6]
        },
        {
            name: 'Repeat yearly',
            value: REPEAT_YEARLY,
            unit: 'years',
            repeatEveryOptions: []
        }
    ];

    const getRepeatSyntax = (v, interval) => interval > 1 ? (v % interval > 0 ? v % interval : interval).toString() + '/' + interval.toString() : '*';

    const getCrontabSyntax = (v) => {
        const month = parseInt(v.startDate.substring(5, 7), 10);
        const date = parseInt(v.startDate.substring(8, 10), 10);
        const hours = parseInt(v.startDate.substring(11, 13), 10);
        const minutes = parseInt(v.startDate.substring(14, 16), 10);
        let crontab = ['*', '*', '*', '*', '*'];
        switch(v.repeat) {
            case REPEAT_MINUTELY:
                crontab[0] = getRepeatSyntax(minutes,  v.repeatEvery);
                break;
            case REPEAT_HOURLY:
                crontab[0] = minutes.toString();
                crontab[1] = getRepeatSyntax(hours, v.repeatEvery);
                break;
            case REPEAT_DAILY:
            case REPEAT_WEEKLY:
                crontab[0] = minutes.toString();
                crontab[1] = hours.toString();
                if ((v.repeat == REPEAT_WEEKLY) || (v.repeatEvery == 1)) {
                    crontab[4] = v.repeatOnWeekdays.map((v, i) => v ? i.toString() : false).filter((v) => v !== false).join(',');
                }
                break;
            case REPEAT_MONTHLY:
            case REPEAT_YEARLY:    
                crontab[0] = minutes.toString();
                crontab[1] = hours.toString();
                if (v.repeat == REPEAT_MONTHLY) {
                    crontab[3] = getRepeatSyntax(month, v.repeatEvery);
                }
                else {
                    crontab[3] = month.toString();
                }
                const repeatMonthlyOrYearlyOn = v.repeatMonthlyOrYearlyOn.split('-');
                switch(repeatMonthlyOrYearlyOn[0]) {
                    case 'd':
                        crontab[2] = repeatMonthlyOrYearlyOn[1];
                        break;
                    case 'w':
                        crontab[2] = ['1-7', '8-14', '15-21', '22-28'][parseInt(repeatMonthlyOrYearlyOn[1], 10) - 1];
                        crontab[4] = repeatMonthlyOrYearlyOn[2];
                        break;
                    case 'l':
                        crontab[2] = '22-31';
                        crontab[4] = repeatMonthlyOrYearlyOn[1];
                        break;
                }
                break;
            default:
                crontab[0] = minutes.toString();
                crontab[1] = hours.toString();
                crontab[2] = date.toString();
                crontab[3] = month.toString();
        }
        return crontab.join(' ');
    }

    const getStartDateString = (date, time) => date.toISOString().substring(0, 11) + time + ':00Z';
    
    const values = workflowStepValues[setupActionStep.property]?.value ? JSON.parse(workflowStepValues[setupActionStep.property].value) : {};
    if (values?.date) values.date = new Date(values.date);
    const now = new Date();
    const initialValues = {
        date: new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())),
        time: '00:00',
        repeat: NOREPEAT,
        repeatEvery: '1',
        repeatOnWeekdays: [],
        repeatMonthlyOrYearlyOn: '',
        ...values
    }
    initialValues.startDate = getStartDateString(initialValues.date, initialValues.time);
    initialValues.crontab = getCrontabSyntax(initialValues);

    const stepContext = useContext(WorkflowStepContext);
    const [selectedDate, setSelectedDate] = useState(initialValues.date);
    const [selectedTime, setSelectedTime] = useState(initialValues.time);
    const [selectedRepeat, setSelectedRepeat] = useState(initialValues.repeat);
    const [selectedRepeatEvery, setSelectedRepeatEvery] = useState(initialValues.repeatEvery);
    const [selectedRepeatOnWeekdays, setSelectedRepeatOnWeekdays] = useState(initialValues.repeatOnWeekdays);
    const [selectedRepeatMonthlyOrYearlyOn, setSelectedRepeatMonthlyOrYearlyOn] = useState(initialValues.repeatMonthlyOrYearlyOn);
    const [repeatMonthlyOrYearlyOnOptions, setRepeatMonthlyOrYearlyOnOptions] = useState([]);
    const [stepValue, setStepValue] = useState(initialValues);

    const fromYear = new Date().getFullYear();

    const createRepeatMonthlyOrYearlyOnOptions = (d) => {
        const weekDay = d.getDay();
        const date = d.getDate();
        const month = d.getMonth();
        const daysInMonth = new Date(d.getFullYear(), month + 1, 0).getDate();
        const options = [
            {
                name: 'On {MPL}{YPL} ' + date.toString(),
                monthPlaceholder: 'day',
                yearPlaceholder: MONTHS[ month ],
                value: 'd-' + date.toString()
            },
            ...(date < 29 ? [{
                name: 'On the ' + ['first', 'second', 'third', 'fourth'][Math.ceil(date / 7) - 1] + ' '
                    + ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][weekDay] + '{YPL}',
                monthPlaceholder: '',
                yearPlaceholder: ' of ' + MONTHS[ month ],
                value: 'w-' + Math.ceil(date / 7).toString() + '-' + weekDay.toString()
            }] : []),
            ...(date > daysInMonth - 7 ? [{
                name: 'On the last '
                    + ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][weekDay] + '{YPL}',
                monthPlaceholder: '',
                yearPlaceholder: ' of ' + MONTHS[ month ],
                value: 'l-' + weekDay.toString()
            }] : [])
        ];
        setRepeatMonthlyOrYearlyOnOptions(options);
    }

    useEffect(() => {
        createRepeatMonthlyOrYearlyOnOptions(initialValues.date);
    }, []);

    const repeatOnOptions = WEEKDAYS.map((v) => ({
        title: v,
        text: v.substring(0, 2).toUpperCase()
    }));
    
    const handleNodeValueChange = (nodeId, value) => {
        onStepValueChange(nodeId, { value });
    }

    const addToStepValue = (obj) => {
        const value = {
            ...stepValue,
            ...obj
        };
        value.crontab = getCrontabSyntax(value);
        setStepValue(value);
        handleNodeValueChange(setupActionStep.property, JSON.stringify(value));
    }

    const handleDateSelect = (e) => {
        setSelectedDate(e);
        createRepeatMonthlyOrYearlyOnOptions(e);
        const utcDate = new Date(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate()));
        addToStepValue({
            date: utcDate,
            startDate: getStartDateString(utcDate, selectedTime)
        });
    }

    const handleTimehange = (e) => {
        setSelectedTime(e.target.value);
        const utcDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()));
        addToStepValue({
            time: e.target.value,
            startDate: getStartDateString(utcDate, e.target.value)
        });
    }

    const handleRepeatSelect = (e) => {
        setSelectedRepeat(e);
        if ([REPEAT_DAILY, REPEAT_WEEKLY].includes(e)) {
            const weekDay = selectedDate.getDay();
            const repeatOnWeekdays = Array.from({ length: 7 }, (_, i) => (e == REPEAT_DAILY) || (i == weekDay));
            setSelectedRepeatOnWeekdays(repeatOnWeekdays);
            addToStepValue({
                repeat: e,
                repeatOnWeekdays: repeatOnWeekdays
            });
        }
        else {
            addToStepValue({
                repeat: e
            });
        }
    }

    const handleRepeatEverySelect = (e) => {
        setSelectedRepeatEvery(e);
        addToStepValue({
            repeatEvery: e
        });
    }

    const handleRepatOnWeekdaysClick = (e) => {
        const ind = parseInt(e.target.id.slice(-1), 10);
        const repeatOnWeekdays = selectedRepeatOnWeekdays;
        repeatOnWeekdays[ind] = !repeatOnWeekdays[ind];
        setSelectedRepeatOnWeekdays(repeatOnWeekdays);
        addToStepValue({
            repeatOnWeekdays: repeatOnWeekdays
        });
    }

    const handleRepeatMonthlyOnSelect = (e) => {
        setSelectedRepeatMonthlyOrYearlyOn(e);
        addToStepValue({
            repeatMonthlyOrYearlyOn: e
        });
    }


    return (
        <div className='mt-4'>
            <div className='calendar-scheduler grid grid-cols-1 lg:grid-cols-2'>
                <DayPicker
                    mode="single"
                    fromYear={fromYear}
                    toYear={fromYear + 1}
                    defaultMonth={new Date()}
                    selected={selectedDate ? selectedDate : null}
                    onSelect={handleDateSelect}
                    captionLayout="dropdown"
                    weekStartsOn={1}
                />
                <div>
                    <label>At: <input type="time" value={selectedTime} onChange={handleTimehange} /> UTC time</label>
                    <Select
                        id="repeatSelect"
                        name="repeatSelect"
                        className="mt-4"
                        selectedValue={selectedRepeat}
                        onChange={handleRepeatSelect}
                        options={repeatOptions}
                    />        
                    {[REPEAT_MINUTELY, REPEAT_HOURLY, REPEAT_DAILY, REPEAT_WEEKLY, REPEAT_MONTHLY].includes(selectedRepeat) && 
                        <label className="block mt-4">Repeat every
                            <Select
                                id="repeatEverySelect"
                                name="repeatEverySelect"
                                className="inline-block mx-2"
                                selectedValue={selectedRepeatEvery}
                                onChange={handleRepeatEverySelect}
                                options={repeatOptions.find((x) => selectedRepeat === x.value)?.repeatEveryOptions.map((x) => ({
                                    name: x,
                                    value: x
                                }))}
                            />
                        {repeatOptions.find((x) => selectedRepeat === x.value)?.unit}</label>
                    }
                    {(([REPEAT_DAILY].includes(selectedRepeat) && selectedRepeatEvery == 1) || [REPEAT_WEEKLY].includes(selectedRepeat)) && 
                        <div className="mt-4">Repeat on
                            <div>
                                {(() => {
                                    const buttons = [];
                                    for (let i = 1; i <= 7; i++) {
                                        buttons.push(
                                            <>
                                                <button
                                                    id={'repeatOn' + (i % 7).toString()}
                                                    title={repeatOnOptions[i % 7].title}
                                                    className={'rounded-full border-3 w-10 h-10 mr-1'
                                                        + (selectedRepeatOnWeekdays?.[i % 7] ? ' rdp-day_selected' : '' )
                                                    }
                                                    onClick={handleRepatOnWeekdaysClick}
                                                >{repeatOnOptions[i % 7].text}
                                                </button>
                                            </>
                                        )
                                    }
                                    return buttons;
                                })()}
                            </div>
                        </div>                         
                    }
                    {[REPEAT_MONTHLY, REPEAT_YEARLY].includes(selectedRepeat) && 
                        <label className="mt-4">Repeat on
                            <Select
                                id="repeatMonthlyOrYearlyOn"
                                name="repeatMonthlyOrYearlyOn"
                                className=""
                                selectedValue={selectedRepeatMonthlyOrYearlyOn}
                                onChange={handleRepeatMonthlyOnSelect}
                                options={repeatMonthlyOrYearlyOnOptions.map((x) => ({
                                    ...x,
                                    name: x.name
                                        .replace('{MPL}', selectedRepeat == REPEAT_MONTHLY ? x.monthPlaceholder: '')
                                        .replace('{YPL}', selectedRepeat == REPEAT_YEARLY ? x.yearPlaceholder: '')
                                }))}
                            />
                        </label>                         
                    }
                </div>
            </div>
        </div>
    )
}
