import { useEffect } from "react";

const useClickOutside = (ref, onOutsideClick, ignoreRef) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && !ignoreRef) {
                onOutsideClick();
            } else if (ref.current && !ref.current.contains(event.target) && ignoreRef.current && !ignoreRef.current.contains(event.target)) {
                onOutsideClick();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
};

export default useClickOutside;