import { OidcRoutesContext } from "contexts";
import { useContext } from "react";

export const useHasProductLicensed = () => {
    const { customerInfo } = useContext(OidcRoutesContext);

    return {
        hasProductLicensed: (appEvent) => {
            if (!appEvent.productCode || !customerInfo || !customerInfo.licencedProductCodes)
                return true;

            if (appEvent.productCode.includes(',')) {
                const productCodes = appEvent.productCode.split(',');
                return productCodes.every(x => customerInfo.licencedProductCodes.includes(x));
            }

            return customerInfo.licencedProductCodes.find(x => x === appEvent.productCode);
        }
    }
}