import { useServerSideTableActions, useToastAction, ButtonV2, CoreButtonTypes, Table } from '@metaforcelabs/metaforce-core';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getWorkflowTableData, deleteWorkflowTableData } from '../../../api/workflowTables';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { WorkflowTableCtxMenu } from './Components/workflowTableCtxMenu';
import { TrashIcon } from "@heroicons/react/outline";
import { workflowTableFieldType, workflowTableRowStatus } from "../../../utils/constants";
import { PageHeader } from '@metaforcelabs/metaforce-core';
import { Checkbox } from '../../../components/Form/Checkbox';
import { useTableFilterHelper } from '../../../hooks/useTableFilterHelper';
import Select from "react-select";

export default function WorkFlowTableData() {
    const [workflowTableData, setWorkflowTableData] = useState({
            name: '',
            description: '',
            fields: [],
            totalRows: 0,
            rowData: []
        });
    const [filterableProperties, setFilterableProperties] = useState([]);
    const [filters, setFilters] = useState([]);
    const [pagination, setPagination] = useState({
        take: 30,
        skip: 0
    });
    const { workflowTableId } = useParams();

    const tableFilterHelper = useTableFilterHelper();

    const loadAction = useToastAction();

    const showIdField = false;

    const loadData = async (filters, take, skip) => {
        loadAction.execute(async () => {
            const validFilters = filters.filter(f => f.valid);
            const tableData = await getWorkflowTableData(workflowTableId, validFilters.map(f => f.property).join(','), validFilters.map(f => f.type).join(','), validFilters.map(f => f.value).join(','), take, skip);
            setWorkflowTableData(tableData);
            if (filterableProperties.length == 0) {
                setFilterableProperties(tableFilterHelper.getFilterableProperties(tableData));
            }
        }, "Failed to load workflow table");
    }

    const tableActions = useServerSideTableActions(workflowTableData.rowData, pagination.take, workflowTableData.totalRows, (skip, take) => {
        setPagination({
            take: take,
            skip: skip
        });
        loadData(filters, take, skip);
    });

    const outputData = (row, field) => {
        switch (field.type) {
            case workflowTableFieldType.string:
                return <td className="px-6 py-3">{row?.columnData[field.id]}</td>
            case workflowTableFieldType.integer:
            case workflowTableFieldType.double:
                return <td className="px-6 py-3 text-right">{row?.columnData[field.id]?.toString()}</td>
            case workflowTableFieldType.boolean:
                return <td className="px-6 py-3">
                    <Checkbox
                        id={row.id + '-' + field.id}
                        name={row.id + '-' + field.id}
                        label=""
                        onChange={() => {}}
                        disabled={true}
                        checked={row?.columnData[field.id]}
                    />
                </td>
            case workflowTableFieldType.date:
                return <td className="px-6 py-3 whitespace-nowrap text-sm">{stringToLocaleDateTimeString(
                    row?.columnData[field.id]
                )}</td>
        }
        return <td className="px-6 py-3">Unknown field type</td>
    }
   
    const handleDestroyWorkflowTableRow = async (workflowTableRow, fieldIndex) => {
        const rowData = workflowTableData.rowData.slice(0);
        rowData.splice(fieldIndex, 1);
        setWorkflowTableData({
            ...workflowTableData,
            ...{
                rowData: rowData
            }
        });
        deleteWorkflowTableData(workflowTableId, workflowTableRow.id);
    }

    useEffect(() => {
        loadData(filters, pagination.take, pagination.skip);
    }, [workflowTableId])

    return (
        <>
            <div className="h-full">
                <div className="max-w-screen-2xl h-full mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
                    <PageHeader
                        title={workflowTableData?.name}
                    />
                    <div className="whitespace-pre-wrap mb-16">{workflowTableData?.description}</div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-3 mb-4 max-w-3xl float-right">
                        {filters.map((f, i) => (
                            <>
                                <div className="col-span-1">
                                    <Select
                                        name={"filter-property-" + i.toString()}
                                        id={"filter-property-" + i.toString()}
                                        className="focus:ring-brand-pink focus:border-brand-pink w-full rounded-md sm:block sm:text-sm border-gray-300 capitalize"
                                        placeholder="Field name"
                                        isSearchable={false}
                                        options={tableFilterHelper.getFilterPropetyOptions(filterableProperties, filters, i)}
                                        onChange={e => {setFilters(tableFilterHelper.filterOperationSetValue(filters, i, tableFilterHelper.getFilterPropetyOnchangeValue(filterableProperties, e.value)))}}
                                        value={tableFilterHelper.getFilterPropetyValue(filters[i])}
                                    />
                                </div>
                                <div className="col-span-1">
                                    {filters[i].property && (
                                        <Select
                                            name={"filter-type-" + i.toString()}
                                            id={"filter-type-" + i.toString()}
                                            className="focus:ring-brand-pink focus:border-brand-pink w-full rounded-md sm:block sm:text-sm border-gray-300 capitalize"
                                            placeholder="Type"
                                            isSearchable={false}
                                            options={tableFilterHelper.getFilterTypeOptions(filters[i])}
                                            onChange={e => {setFilters(tableFilterHelper.filterOperationSetValue(filters, i, {type: e.value}))}}
                                            value={tableFilterHelper.getFilterTypeValue(filters[i])}
                                        />
                                    )}
                                </div>
                                <div className="flex flex-row justify-end">                                   
                                    {filters[i].property && (
                                        <div className="flex-grow">
                                            {[workflowTableFieldType.status, workflowTableFieldType.boolean].includes(filters[i].fieldType) ? (
                                                <Select
                                                    name={"filter-value-" + i.toString()}
                                                    id={"filter-value-" + i.toString()}
                                                    className="focus:ring-brand-pink focus:border-brand-pink w-full rounded-md sm:text-sm border-gray-300 capitalize"
                                                    placeholder="Value"
                                                    isSearchable={false}
                                                    options={tableFilterHelper.getFilterValueOptions(filters[i])}
                                                    onChange={e => {setFilters(tableFilterHelper.filterOperationSetValue(filters, i, {value: e.value}))}}
                                                    value={tableFilterHelper.getFilterValueValue(filters[i])}
                                                />
                                            ): (
                                                <input  
                                                    type={tableFilterHelper.getFilterValueType(filters[i])}
                                                    name={"filter-value-" + i.toString()}
                                                    id={"filter-value-" + i.toString()}
                                                    className="focus:ring-brand-pink focus:border-brand-pink w-full rounded-md sm:text-sm border-gray-300 "
                                                    placeholder="Value"
                                                    onChange={e => {setFilters(tableFilterHelper.filterOperationSetValue(filters, i, {value: e.target.value}))}}
                                                />
                                            )}
                                        </div>
                                    )}
                                    <button
                                        className="group flex rounded-md items-center px-2 py-2 text-sm text-gray-700"
                                        onClick={e => {
                                            const newFilters = tableFilterHelper.filterOperationRemove(filters, i);
                                            setFilters(newFilters);
                                            loadData(newFilters, pagination.take, pagination.skip);
                                        }}
                                    >
                                        <TrashIcon
                                            className="w-5 h-5 mr-2"
                                            aria-hidden="true"
                                        />
                                    </button>  
                                </div>
                            </>
                        ))}
                    </div>
                    <div className="text-right clear-right">
                        <div className="inline-block mr-4">
                            <ButtonV2
                                label={"Add filter"}
                                type={CoreButtonTypes.white}
                                onClick={() => setFilters(tableFilterHelper.filterOperationAdd(filters))}
                                disabled={filters.filter(f => !f.valid).length > 0}
                            />
                        </div>
                        <div className="inline-block">
                            <ButtonV2
                                label={"Apply filters"}
                                type={CoreButtonTypes.cta}
                                onClick={() => loadData(filters, pagination.take, pagination.skip)}
                                disabled={filters.filter(f => f.valid).length == 0}
                            />
                        </div>
                    </div>
                    <div className="flex h-full gap-6 mt-6">
                        <div className="flex-1 h-full">
                            <div className="relative z-0">
                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        {workflowTableData?.fields.map((f) => 
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 text-left text-xs font-medium whitespace-nowrap"
                                                            >
                                                                {f.name}
                                                            </th>
                                                        )}
                                                        {showIdField && (
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 whitespace-nowrap uppercase"
                                                            >
                                                                Id
                                                            </th>
                                                        )}
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 whitespace-nowrap uppercase"
                                                        >
                                                            Status
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 whitespace-nowrap uppercase"
                                                        >
                                                            Retries
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 whitespace-nowrap uppercase"
                                                        >
                                                            Created
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 whitespace-nowrap uppercase"
                                                        >
                                                            Updated
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="relative px-6 py-3"
                                                        >
                                                            <span className="sr-only">Edit</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {workflowTableData?.rowData.map((row, i) => (
                                                        <tr>
                                                            {workflowTableData?.fields.map((f) => outputData(row, f))}
                                                            {showIdField && (
                                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                    {row.id}
                                                                </td>
                                                            )}
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
                                                                {Object.keys(workflowTableRowStatus).find(k => workflowTableRowStatus[k] === row.status)}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                                                                {row.retries.toString()}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                {stringToLocaleDateTimeString(
                                                                    row.updatedDate
                                                                )}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                {stringToLocaleDateTimeString(
                                                                    row.updatedDate
                                                                )}
                                                            </td>
                                                            <td className="p-2 whitespace-nowrap text-sm font-medium">
                                                                <WorkflowTableCtxMenu workflowTableRow={row} fieldIndex={i} 
                                                                    onDestroy={(wf, i) => handleDestroyWorkflowTableRow(wf, i)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <Table.Pagination tableActions={tableActions} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
