import { ContentWrapper, Table as PaginationTable, stringToLocaleDateTimeString, useModalHelper, useTableActions, useToastAction } from "@metaforcelabs/metaforce-core";
import React, { useEffect, useRef, useState } from "react";
import { EnvironmentBadge } from "../../../components/environmentBadge";
import { getWorkflowDefinitionVersions, promoteWorkflowDefinition, rollbackWorkflowDefinition } from "../../../api/workflowDefinition";
import { useParams } from "react-router-dom";
import { Button } from "../../../components/Form/Button";
import { centerPointEnvironments, centerpointEnvironmentNames } from "../../../utils/constants";
import { Form, Formik } from "formik";
import Modal from "../../../components/Modals/modal";
import { Checkbox } from "../../../components/Form/Checkbox";
import PromotePreview, { RollbackPreview } from "./promotePreview";

const environments = Object.keys(centerpointEnvironmentNames).map(k => ({ name: centerpointEnvironmentNames[k], value: k }));

const defaultFilter = environments.reduce((agg, curr) => {
    return { ...agg, [curr.value]: false }
}, {});

export default function WorkflowVersions() {

    //#region Modal data initialize
    const onModalClose = () => {
        setModalData(modalInitialState)
        modalHelper.close()
    }
    const modalInitialState = { title: "", size: "large", onModalClose, children: null }
    const [modalData, setModalData] = useState(modalInitialState);
    //#endregion

    const { workflowId } = useParams();
    const [currentFilter, setCurrentFilter] = useState(defaultFilter);
    const [versions, setVersions] = useState([])
    const [filteredVersions, setFilteredVersions] = useState([]);
    const tableActions = useTableActions(filteredVersions, 20);
    const loadAction = useToastAction();
    const updateAction = useToastAction();
    const modalHelper = useModalHelper();
    const load = async () => {
        loadAction.execute(async () => {
            const versionsResult = await getWorkflowDefinitionVersions(workflowId);
            console.log(versionsResult)
            setVersions(versionsResult);
            handleFilterChange(currentFilter, versionsResult);
        }, "Failed to load");
    }

    useEffect(() => {
        load();
    }, [])


    const handleOpenOpenPromoteModal = (version) => {
        const nextEnv = getNextEnv(version.environment);

        setModalData({
            ...modalInitialState,
            title: 'Promote version',
            children: <PromotePreview version={version} nextEnv={nextEnv} onCancel={modalHelper.close} onConfirm={handlePromoteVersion} />
        });
        modalHelper.open();
    }

    const onOpenRollbackModal = (version) => {
        setModalData({
            ...modalInitialState,
            children: <RollbackPreview version={version} onCancel={modalHelper.close} onConfirm={handleRollbackVersion} />,
            title: "Preview"
        })

        modalHelper.open()
    }

    const handlePromoteVersion = async (version) => {
        await updateAction.executeAsync(async () => {
            const promoted = await promoteWorkflowDefinition(version.id);

            let updatedVersions = [...versions];
            promoted.isLatestEnvVersion = true;
            const idx = updatedVersions.findIndex(x => x.environment === promoted.environment && x.isLatestEnvVersion);
            if (idx > -1) {
                updatedVersions[idx].isLatestEnvVersion = false;
            }

            updatedVersions = [promoted, ...updatedVersions];
            setVersions(updatedVersions);
            handleFilterChange(currentFilter, updatedVersions);
        }, 'Promotion Failed');
        modalHelper.close();
    }

    const handleRollbackVersion = async (version) => {
        await updateAction.executeAsync(async ()=>{
            const rollback = await rollbackWorkflowDefinition(workflowId,version.id);
            modalHelper.close();
            load();
        },"Rollback failed");
    }

    const canPromote = (version) => {
        // if (!featureFlags.smartformEnvironment) {
        //     return false;
        // }
        const nextEnv = getNextEnv(version.environment);
        return version.isLatestEnvVersion && !!nextEnv;
    }

    const getNextEnv = (currentEnv) => {
        const envs = Object.keys(centerpointEnvironmentNames).map(k => +k);
        return envs.filter(x => x > +currentEnv)[0];
    }

    const getNextEnvName = (currentEnv) => {
        const nextEnv = getNextEnv(currentEnv);
        return centerpointEnvironmentNames[nextEnv];
    }

    const handleFilterChange = (filter, providedVersions = null) => {
        if (providedVersions == null) {
            providedVersions = versions;
        }
        let filtered = [...providedVersions.filter(x => {
            return x.isLatestEnvVersion ||
                (x.environment === centerPointEnvironments.development && filter[centerPointEnvironments.development]) ||
                (x.environment === centerPointEnvironments.test && filter[centerPointEnvironments.test]) ||
                (x.environment === centerPointEnvironments.preProd && filter[centerPointEnvironments.preProd]) ||
                (x.environment === centerPointEnvironments.production && filter[centerPointEnvironments.production])
        })];
        setCurrentFilter(prev => filter);
        setFilteredVersions(prev => filtered)

    }


    return (
        <ContentWrapper>
            <div className="pb-4  mt-10">
                <div className="pb-5 border-b border-gray-200 flex justify-between">
                    <div className=''>
                        <h1 className="text-2xl font-bold leading-6 font-medium  text-gray-900">Workflow Versions</h1>
                        <p className="mt-2 max-w-4xl text-sm text-gray-500">
                            Administrate versions and environments
                        </p>
                    </div>
                    <div className='self-end'>
                        <VersionsFilter defaultFilter={currentFilter} onChange={handleFilterChange} />
                    </div>
                </div>
            </div>
            {/* <div className="pb-4">
            <div className="pb-5 border-b border-gray-200 mb-6">
              
            </div>
          </div> */}
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                        <Table tableActions={tableActions} className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >

                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Created
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Updated
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Environment
                                    </th>
                                    <th scope="col" className="relative px-6 py-3" />
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {tableActions.pageData.map((element) => (
                                    <tr key={element.id}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{element.record.name}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stringToLocaleDateTimeString(element.record.createdDate)}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stringToLocaleDateTimeString(element.record.updatedDate)}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium"><EnvironmentBadge environment={element.environment} isLatest={element.isLatestEnvVersion} /></td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <div className='inline-flex gap-2'>
                                                {
                                                    canPromote(element) && (
                                                        <Button
                                                            theme="text"
                                                            text={"Promote to " + getNextEnvName(element.environment)}
                                                            onClick={() => handleOpenOpenPromoteModal(element)}
                                                        />
                                                    )
                                                }
                                                {/* TODO: Create preview mode ? */}
                                                {/* <Button
                                                    theme="text"
                                                    text={"Preview"}
                                                    // onClick={() => onOpenPreviewRollbackModal(element.id)}
                                                /> */}
                                                {
                                                    element.isLatestEnvVersion && (<Button
                                                        theme="text"
                                                        text={"Rollback"}
                                                        onClick={() => onOpenRollbackModal(element)}
                                                    />)
                                                }

                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <ModalWrapper data={modalData} isOpen={modalHelper.isOpen} />
        </ContentWrapper>
    )
}

export const Table = ({ tableActions, children, ...props }) => {
    return (
        <div className="flex flex-col" {...props}>
            <div className="-my-2 sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">
                    <div className="shadow-sm border border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            {children}
                        </table>
                        {
                            tableActions &&
                            <PaginationTable.Pagination tableActions={tableActions} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const VersionsFilter = ({ defaultFilter, onChange }) => {
    const submitRef = useRef(null);

    const onFormChange = () => {
        submitRef.current?.click();
    }

    return (
        <div>
            <Formik
                initialValues={{
                    ...defaultFilter
                }}
                onSubmit={async (values) => {
                    console.log('Form submit')
                    onChange(values)
                }}
            >{({ values, handleChange, submitCount, ...props }) => (
                <Form onChange={onFormChange}>
                    {/* <div className='mb-2'><Label label={"Environment"} /></div> */}
                    <div className='flex space-x-4'>
                        {environments.map(e => {
                            return <Checkbox checked={values[e.value]} label={e.name} name={e.value} onChange={evt => {
                                handleChange({ target: { name: e.value, value: evt } })
                            }} />
                        })}
                    </div>
                    <button ref={submitRef} type='submit' name={'submitfilter'} className='sr-only'></button>
                </Form>
            )}
            </Formik>
        </div>
    )
}

const ModalWrapper = ({ data, isOpen }) => {
    const { title, size, onModalClose, children } = data;

    return (
        <Modal
            size={size}
            title={title}
            isOpen={isOpen}
            onClose={onModalClose}
        >
            {children}
        </Modal>
    )
}