import { useClassNames } from '@metaforcelabs/metaforce-core';
import React, { useContext, useEffect, useState } from "react";
import { BranchContext, WorkflowContext, WorkflowStepContext, WorkflowValuesContext } from "../../../../contexts";
import { usePreviousSteps } from "../../../../hooks/usePreviousSteps";
import { appEventIconName, branchType, workflowAppType } from "../../../../utils/constants";
import WorkflowEventAppIcon from "../workflowAppIcon";
import NextStepArrow from "../Components/nextStepArrow";
import { BranchConditionsSetupAction, LoopConditionSetupAction } from "../Components/SetupActionSteps/filterConditionsSetupActionStep";
import { stepValidator } from "../stepValidator";
import { convertValuesToArray } from "../utils";
import { isArray } from 'lodash';

export const BranchTriggerConfigStep = ({ branch, activeStep, setActiveStep }) => {

    const [workflowStepid, setWorkflowStepid] = useState();
    const {workflowStepValues, workFlow: workflow, mapDialogElementsToWorkflowElement,...workflowContext } = useContext(WorkflowContext);
    const branchContext = useContext(BranchContext);
    const { workFlowValues } = useContext(WorkflowValuesContext);
    const { classNames } = useClassNames();
    const [previousSteps, setPreviousSteps] = useState([]);
    const { filterPreviousSteps } = usePreviousSteps(workflow.workflowSteps, workflow.workflowBranches);
    const [previousStepsValues, setPreviousStepsValues] = useState([]);

    const handleFilterGroupsChange = (filterGroups) => {
        branch.triggerFilterGroups = filterGroups;
        workflowContext.updateBranch(branch)
    }

    const handleLoopBranchConfigChange = (config) => {
        branch.loopBranchConfig = config;
        workflowContext.updateBranch(branch)
    }

    const handleAddNewStepToBranch = () => {
        workflowContext.addStep(null, branch.id)
    }

    useEffect(() => {
        setWorkflowStepid(branch.stepId);
        loadPrevSteps(branch.stepId);
        // console.log(branch)
    }, []);

    const loadPrevSteps = (stepId) => {
        const steps = filterPreviousSteps(stepId).map(({ appEvent, id, name }, i) => {
            const stepValue = workflowStepValues.find(x => x.workflowStepId === id);
            return { appEvent, id, name, stepIdx: i, values: stepValue?.values || [] }
        });

        setPreviousSteps(steps);
        
        const prevStepsValues = steps.map(step => {
            return step.values.map(v => ({
                name: v.value.name,
                id: v.key,
                stepIdx: step.stepIdx,
                stepName: step.name,
                stepId: step.id,
                isArray: v.value.isArray,
                icon: step.appEvent.appIcon
            }));
        });

        setPreviousStepsValues(prevStepsValues);
        // validateStep(prevStepsValues);
    }

    useEffect(() => {
        // the config "step" of each branch will enter here with brach id as that step doesnt hold any stepId
        // we therefore work out the parent step of that branch as the step for which we are retreiveing previous values from
        if (!workflowStepid) {
            return;
        }

        loadPrevSteps(workflowStepid);

    }, [workflow]);

    // useEffect(() => {
    //     loadStepsValues();
    // }, [previousSteps]);

    // useEffect(() => {
    //     validateStep();
    // }, [previousStepsValues]);

    const getIconForType = (bt) => {
        if (!bt) return appEventIconName.branchIcon;

        switch (bt) {
            case branchType.branch: return appEventIconName.branchIcon;
            case branchType.distribution: return appEventIconName.distributionIcon;
            case branchType.loop: return appEventIconName.loopIcon;
            default: return appEventIconName.branchIcon;
        }
    }

    const branchIsConditional = ()=>{
        switch (branch.branchType) {
            case branchType.loop: return false;
            case branchType.branch: 
            case branchType.distribution: 
            default: return true;
        }
    }

    return (
        <WorkflowStepContext.Provider value={{
            previousSteps: previousSteps,
            previousStepsValues: previousStepsValues,
        }}>
            <div className="max-w-4xl">
                <div className={classNames("w-fullrounded-lg border shadow-lg divide-y divide-gray-200",
                    activeStep ? "" : "cursor-pointer opacity-70 hover:opacity-100"
                )}
                    onClick={e => {
                        if (!activeStep) {
                            setActiveStep();
                        }
                    }}
                >

                    <div className={classNames("flex justify-between space-x-4 items-center px-4 py-5 sm:px-6",
                        "bg-white",
                        activeStep ? "rounded-t-lg" : "rounded-lg")}>
                        <div className={classNames("flex items-center space-x-2 sm:space-x-4 w-full sm:w-3/4")}>
                            <>
                                <WorkflowEventAppIcon iconKey={getIconForType(branchContext.branch.branchType)} className="flex-none rounded-lg shadow h-14 w-14 p-2" />
                                <div className="w-full">
                                    <div>
                                        <span className="text-gray-600">
                                            {
                                                (!branchContext || (branchContext && branchContext.branch.branchType === branchType.branch)) && ("Branch")
                                            }
                                            {
                                                branchContext && branchContext.branch.branchType === branchType.distribution && ("Distribution")
                                            }
                                        </span>
                                    </div>
                                    <div><h3 className="py-1 font-semibold border border-white" >
                                        {
                                            (!branchContext || (branchContext && branchContext.branch.branchType === branchType.branch)) && ("1. Branch Rules")
                                        }
                                        {
                                            branchContext && branchContext.branch.branchType === branchType.distribution && ("1. Distribution Rules")
                                        }
                                        
                                    </h3></div>
                                </div>
                            </>
                        </div>
                        <div>

                        </div>
                    </div>
                    {
                        activeStep && branchIsConditional() && (
                            <div className="bg-white divide-y divide-gray-200 py-4 px-6">
                                <BranchConditionsSetupAction branchId={branch.id} branchFilterGroups={branch.triggerFilterGroups} onFilterGroupsChange={filterGroups => handleFilterGroupsChange(filterGroups)} />
                            </div>
                        )
                        
                    }
                    {
                         branch?.branchType === branchType.loop && (
                            <div className="bg-white divide-y divide-gray-200 py-4 px-6">
                                
                                <LoopConditionSetupAction branchId={branch.id} loopBranchConfig={branch.loopBranchConfig || {}} onLoopBranchConfigChange={config=> handleLoopBranchConfigChange(config)} />
                            </div>
                        )
                    }

                </div >
                <NextStepArrow isLastStep={false} onAddStep={() => { handleAddNewStepToBranch() }} />
            </div>
        </WorkflowStepContext.Provider >
    );
}