import { useToastAction } from '@metaforcelabs/metaforce-core';
import React, { useContext, useEffect, useState } from "react";
import { getActiveDialogDefinitions } from "../../../../../api/dialogDefinition";
import DataOptionsMenu from "../dataOptionsMenu";
import { getFolders } from 'api/archive';
import { WorkflowContext } from 'contexts';
import { getEnvironmentGroups } from '../../../../../api/pagesManager';
import { getWorkflowTables } from '../../../../../api/workflowTables';

export const ApiOptionsSetupActionStep = ({ setupActionStep, workflowStepValues, onStepValueChange }) => {
    const loadDataAction = useToastAction();
    const [optionsData, setOptionsData] = useState([]);
    const { workFlow: workflow } = useContext(WorkflowContext);

    const loadData = async () => {
        loadDataAction.execute(async () => {
            switch (setupActionStep.dataSourceId) {
                case "active-smartforms":
                    setSmartformOptions();
                    break;
                case "archive-folders":
                    const activeFolders = await getFolders();
                    setOptionsData(activeFolders.map(f => ({ name: f.name, value: f.id })));
                    break;
                case "update-values-smartforms":
                    const triggerValue = workflow?.values && workflow?.values?.length > 1 ? workflow?.values[1]?.value : null;
                    const triggerType = workflow?.workflowSteps[0]?.selectedEvent?.key;
                    if (triggerValue && triggerType == "value-change") {
                        break;
                    }
                    setSmartformOptions();
                    break;
                case "active-pagesmanager-groups":
                    const activeGroups = await getEnvironmentGroups();
                    setOptionsData(activeGroups.filter(x => x.isActive).map(f => ({ name: f.name, value: f.id })));
                case "active-tables":
                    const activeTables = await getWorkflowTables();
                    // setOptionsData(activeTables.map(f => ({ name: f.name, value: f.id, fields: f.fields })));
                    setOptionsData(activeTables.map(f => ({ name: f.name, value: f.id })));
                    break;
                default:
                    break;
            }
        }, "Load failed")
    }

    const setSmartformOptions = async () => {
        const activeSF = await getActiveDialogDefinitions();
        setOptionsData(activeSF.sort((a, b) => new Date(b.updatedDate) - new Date(a.updatedDate)).map(({ id, name }) => ({ value: id, name })));
    }

    useEffect(() => {
        loadData();
    }, [setupActionStep.dataSourceId]);

    const handleStepValueChange = (property, value) => {
        onStepValueChange(property, value)
    }

    return (
        <div className="mt-4">
            <h4 className="font-semibold">{setupActionStep.name}{
                setupActionStep?.required ? "*" : ""
            }:</h4>
            <DataOptionsMenu
                optionsData={optionsData}
                selectedValue={workflowStepValues[setupActionStep.property]?.name}
                selectedValueValid={workflowStepValues[setupActionStep.property]?.valid !== false}
                onSelect={value => handleStepValueChange(setupActionStep.property, value)}
                isLoading={loadDataAction.isExecuting} />
            <div className='text-gray-500 text-sm mt-1'>
                {setupActionStep?.helperText}
            </div>
        </div>);
}