import { stepProcessType } from 'utils/constants';
import { workFlowApps, specialApps } from '../domain/workflowApps';
import { useWorkflowStep } from "../hooks/useWorkflowStep";


export const NO_STEP_EVENT_ALLOWED = "no-step-event-allowed";

export const useWorkflowhelper = (workflow) => {
    const { getSteps, stepIsTrigger } = useWorkflowStep();

    const getAllApps = () => {
        return workFlowApps.concat(specialApps);
    }

    const getWorkflowApp = (appType) => {
        const allApps = getAllApps();
        return allApps.find(x => x.type === appType);
    }

    const getWorkflowAppEvent = (appType, eventKey) => {
        const foundApp = getWorkflowApp(appType);
        return foundApp?.events?.find(x => x.key === eventKey);
    }

    const getAllStepEvents = () => {
        const allEvents = workFlowApps.map(x => x.events).flat();
        const specialAppEvents = Object.values(specialApps).map(x => x.events).flat();
        return allEvents.concat(specialAppEvents);
    }

    const getAllowedFutureStepProcessTypeStepEvents = (workflowStep) => {
        if (!workflowStep.selectedEvent?.allowdFutureStepProcessTypes?.length)
            return [];

        const allStepEvents = getAllStepEvents();

        const allowedStepEvents = allStepEvents
            .map(event => {
                return {
                    ...event,
                    stepProcessTypes: event.stepProcessTypes ?? [stepProcessType.background]
                }
            })
            .filter(event => event.stepProcessTypes.every(processType => workflowStep.selectedEvent.allowdFutureStepProcessTypes.includes(processType)))
            .map(event => event.key);
        return allowedStepEvents;
    }

    const getStepLimitations = (workflowStep) => {
        const allowedFutureStepEvents = workflowStep.selectedEvent?.allowedFutureStepEvents ?? [];
        const allowdFutureStepProcessTypeStepEvents = getAllowedFutureStepProcessTypeStepEvents(workflowStep);

        if (!workflowStep.selectedEvent?.allowedFutureStepEvents?.length) {
            return allowdFutureStepProcessTypeStepEvents;
        }

        if (!workflowStep.selectedEvent?.allowdFutureStepProcessTypes?.length) {
            return allowedFutureStepEvents;
        }

        // Find the intersection of allowedFutureStepEvents and allowdFutureStepProcessTypes
        const stepEvents = allowedFutureStepEvents.filter(event => allowdFutureStepProcessTypeStepEvents.includes(event));

        if (stepEvents.length === 0) {
            //if thre is no intersection, no steps are allowed
            return [NO_STEP_EVENT_ALLOWED];
        }

        return stepEvents;
    }

    const getAllowedFutureSteps = (currentStepId) => {
        const stepIdx = workflow.workflowSteps.findIndex(x => x.id === currentStepId);
        const prevSteps = workflow.workflowSteps.slice(0, stepIdx);
        const stepRestrictions = prevSteps.map(x => getStepLimitations(x)).filter(allowedStepEvents => allowedStepEvents.length > 0);

        if (stepRestrictions.length === 0) {
            return [];
        }

        //Shortcircut if there is only one step with limitations
        if (stepRestrictions.length === 1) {
            return stepRestrictions[0];
        }

        //all distinct steps limitiations
        const all = stepRestrictions.flat().filter((x, i, self) => self.indexOf(x) === i);
        // if there are multiple steps with limitations, we need to find the intersection of the allowed steps
        const res = all.filter(x => stepRestrictions.every(y => y.includes(x)));

        if (res.length === 0) {
            //if thre is no intersection, no steps are allowed
            return [NO_STEP_EVENT_ALLOWED];
        }

        return res;
    }

    const isAllowedTriggerEventForStepEvent = (currentStepId, eventKey) => {
        const currentStep = workflow.workflowSteps.find(x => x.id === currentStepId);
        const currentEvent = currentStep?.appEvent?.events?.find(x => x.key === eventKey);

        if (!currentStep || !currentEvent?.allowedTriggerEvents?.events?.length)
            return true;

        const stepIdx = workflow.workflowSteps.findIndex(x => x.id === currentStepId);
        const steps = workflow.workflowSteps.slice(0, stepIdx + 1);
        const triggers = steps.filter(s => s.stepType === 1);

        if (!triggers?.length)
            return true;

        var trigger = triggers[0];
        if (!trigger?.selectedEvent)
            return true;

        const triggerEvents = currentEvent.allowedTriggerEvents.events || [];

        const allowed = triggerEvents.length === 0 || triggerEvents.includes(trigger.selectedEvent.key || "");
        return currentEvent.allowedTriggerEvents.invert ? !allowed : allowed;
    }

    return {
        noFutureStepsAreAllowed: (currentStepId) => {
            const currentStep = workflow.workflowSteps.find(x => x.id === currentStepId);
            const currentStepLimitations = currentStep?.selectedEvent?.allowedFutureStepEvents ?? [];
            return [...getAllowedFutureSteps(currentStepId), ...currentStepLimitations].includes(NO_STEP_EVENT_ALLOWED);
        },
        getAllowedFutureSteps: getAllowedFutureSteps,
        getWorkflowApp: getWorkflowApp,
        getWorkflowAppEvent: getWorkflowAppEvent,
        isAllowedTriggerEventForStepEvent: isAllowedTriggerEventForStepEvent,
    }
}