import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { useNavigate } from "react-router";
import { createWorkflowTable } from "../../api/workflowTables";
import { Formik } from "formik";
import * as Yup from 'yup';
import { ButtonV2, CoreButtonTypes, useClassNames } from '@metaforcelabs/metaforce-core';
import { convertAndCreateWorkflowFromTemplate } from "../../utils/templates";

const NewWorkflowTableSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
});


export default function NewWorkflowTable({ open, setOpen, addTable }) {
    const navigate = useNavigate();
    const { classNames } = useClassNames();

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmitForm = async (values) => {
        let workflowTable = await createWorkflowTable(values.name, values.description, []);
        addTable(workflowTable);
        handleClose();
        navigate(`/admin/workflow-table/structure/${workflowTable.id}`);
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-40 inset-0 overflow-y-auto"
                open={open}
                onClose={handleClose}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                            <div className="text-3xl font-semibold mb-4 text-gray-700">Create workflow table </div>
                            <Formik
                                initialValues={{ name: '', description: '' }}
                                validationSchema={NewWorkflowTableSchema}
                                onSubmit={async (values, { setSubmitting }) => {
                                    await handleSubmitForm(values);
                                    setSubmitting(false);
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="">
                                            <div className="pt-2">
                                                <div className="mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                                    <span
                                                        className="flex-grow flex flex-col w-full sm:w-36"
                                                        id="availability-label"
                                                    >
                                                        <span className="text-sm font-medium text-gray-500">
                                                            Table Name
                                                        </span>
                                                    </span>
                                                    <div className="mt-1 relative rounded-md w-full">
                                                        <input
                                                            type={"text"}
                                                            name="name"
                                                            value={values.name}
                                                            className={classNames(`focus:ring-brand-pink focus:border-brand-pink block sm:text-sm rounded-md w-full`,
                                                                errors.name && touched.name ? "border-red-500" : "border-gray-300"
                                                            )}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.name && touched.name ? (
                                                            <div className="text-red-500">{errors.name}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pt-2">
                                                <div className="mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                                    <span
                                                        className="flex-grow flex flex-col w-full sm:w-36"
                                                        id="availability-label"
                                                    >
                                                        <span className="text-sm font-medium text-gray-500">
                                                            Description
                                                        </span>
                                                    </span>
                                                    <div className="mt-1 relative rounded-md w-full">
                                                        <textarea
                                                            type={"text"}
                                                            name="description"
                                                            value={values.description}
                                                            className="focus:ring-brand-pink focus:border-brand-pink block sm:text-sm rounded-md w-full"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-6 flex space-x-2 sm:block">
                                            <ButtonV2
                                                label={"Create"}
                                                type={CoreButtonTypes.primary}
                                                htmlType="submit"
                                                disabled={isSubmitting}
                                            />
                                            <ButtonV2
                                                label={"Cancel"}
                                                type={CoreButtonTypes.white}
                                                onClick={handleClose}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}


