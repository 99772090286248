import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { activateWorkflowDefinition, deactivateWorkflowDefinition, getStepValues, getWorkflowDefinition, publishWorkflow, updateWorkflowDefinition } from '../../../api/workflowDefinition';
import { AdminLayoutContext, SidebarContext, WorkflowContext, WorkflowValuesContext } from '../../../contexts';
import { editStates, workflowAppType, workflowStates, workflowStepType } from '../../../utils/constants';
import WorkFlowStep from './Components/workFlowStep';
import { useDebounceCallback } from '@react-hook/debounce';
import { v4 as uuidv4 } from 'uuid';
import { PencilIcon } from '@heroicons/react/outline';
import WorkflowDefinitionStateBadge from './workflowDefinitionStateBadge';
import FullScreenModal from '../../../components/Modals/fullScreenModal';
import { WorkflowBranchEditor } from './BranchEditor/workflowBranchEditor';
import { GenericModal, useAlertModal, useClassNames, useInputFocusSelect, useToastAction } from '@metaforcelabs/metaforce-core';
import _, { isArray } from "lodash";
import { ContentWrapper } from '@metaforcelabs/metaforce-core';
import { DndContextWrapper } from './DragAndDrop/dndContextWrapper';
import DndSortable from '../../../components/DragAndDrop/dndSortable';
import { useWorkflowStep } from '../../../hooks/useWorkflowStep';
import { getPlaceHolders, updateStepIdxInPlaceholder } from "../../../utils/slate";
import { getDialogDefinition } from '../../../api/dialogDefinition';
import { convertValuesToArray } from './utils';
import { concatObjectStringValues, placeHoldersHaveChanged } from '../../../utils/placeHolders';
import { updateOAuthAppConnectionWorkflowIds } from '../../../api/workflowOAuthAppConnection';
import Sidebar from './sideBar';
import { useSmartFormHelper } from '../../../hooks/useSmartFormHelper';
import { useWorkflowhelper } from 'hooks/useWorkflowHelper';
import WorkflowRunView from './Tracing/workflowRunView';

const convertToListValues = (workflowValues) => {
    const workflowSteps = Object.keys(workflowValues);

    const listed = workflowSteps.flatMap(k => {
        const stepValueKeys = Object.keys(workflowValues[k]);
        // const listedValues = stepValueKeys.map(svk => ({ workflowStepId: k, property: svk, value: workflowValues[k][svk] }))
        const listedValues = convertKeyValueStepValuesToListValues(workflowValues[k], k)
        return listedValues;
    })

    return listed;
}

const convertKeyValueStepValuesToListValues = (valuesForStep, workflowStepId) => {
    const stepValueKeys = Object.keys(valuesForStep);
    const listedValues = stepValueKeys.map(svk => ({ workflowStepId: workflowStepId, property: svk, value: valuesForStep[svk] }))
    return listedValues;
}

const convertToListErrors = (validationErrors) => {
    const stepIds = Object.keys(validationErrors);
    const errors = stepIds.flatMap(workflowStepId => {
        const stepErrors = validationErrors[workflowStepId].map(err => ({ workflowStepId, type: err.type, error: err.error, errorField: err.errorField }))
        return stepErrors;
    });
    return errors;
}

// const convertToListTestValues = (testValues) => {
//     const stepIds = Object.keys(testValues);
//     const valuesList = stepIds.flatMap(workflowStepId => {
//         const stepValues = testValues[workflowStepId];
//         const placeholderIds = Object.keys(stepValues);
//         return placeholderIds.reduce((agg, id, i) => {
//             const value = stepValues[id];
//             if (!value) {
//                 return agg;
//             }
//             return [{ workflowStepId, placeholderId: id, value: value }, ...agg];
//         }, []);
//     });
//     return valuesList;
// }

export default function WorkFlowEditor() {
    const [nameInputRef, setNameInputRef] = useInputFocusSelect();
    const adminLayoutContext = useContext(AdminLayoutContext)
    const { workflowId } = useParams();
    const { workflowValuesId } = useParams();
    const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
    const [activeWorkflowItem, setActiveWorkflowItem] = useState("");
    const [isEditingName, setIsEditingName] = useState(false);
    const [nameHovered, setNameHovered] = useState(false);
    const { classNames } = useClassNames();
    const [workflowDisabledAlertOpen, setWorkflowDisabledAlertOpen] = useState(false);
    const [branchEditModalOpen, setBranchEditModalOpen] = useState(false);
    const [editBranch, setEditBranch] = useState(null);
    const [publishDisabled, setPublishDisabled] = useState(false);
    const [workflow, setWorkFlow] = useState({}
        // {
        //     id: "asdasd",
        //     name: "WorkflowName",
        //     workflowSteps: [
        //         {
        //             id: 'asd',
        //             type: workflowStepType.trigger,
        //             name: ""
        //         }
        //     ]
        // }
    );
    const [workflowUpdatedDate, setWorkflowUpdatedDate] = useState();
    const [workflowValues, setWorkFlowValues] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const validationErrorsRef = useRef({});
    const [workflowTestValues, setWorkflowTestValues] = useState({});
    const [workflowStepValues, setWorkflowStepValues] = useState([])

    const [dragAndDropDisabled, setDragAndDropDisabled] = useState(false);
    const dragAndDropChangedStepsRef = useRef([]);
    const { canMove: canStepMove, getSteps, getCanMovedStepIds } = useWorkflowStep();
    const loadAction = useToastAction();
    const saveAction = useToastAction();
    const activateAction = useToastAction();
    const { alertError, alertWarn } = useAlertModal();
    const smartFormHelper = useSmartFormHelper();
    const { getWorkflowApp, getWorkflowAppEvent } = useWorkflowhelper(workflow);

    const sortableItems = useMemo(() => getCanMovedStepIds(getSteps(workflow)) || [], [workflow]);

    const saveWorkflow = async () => {

        if (!workflow) {
            return;
        }

        saveAction.execute(async () => {
            workflow.values = convertToListValues(workflowValues);
            workflow.errors = convertToListErrors(validationErrors);
            workflow.updatedDate = workflowUpdatedDate || workflow.updatedDate;
            workflow.editState = editStates.draft;
            // workflow.testValues = convertToListTestValues(workflowTestValues);
            const { updatedDate, editState } = await updateWorkflowDefinition(workflow);
            setWorkFlow(prev => {
                return { ...prev, editState: editState }
            })
            // setWorkFlow(prev => updated);
            setWorkflowUpdatedDate(updatedDate);

        }, "Save failed")
    }

    const handlePublish = () => {
        saveAction.execute(async () => {
            const { updatedDate, editState } = await publishWorkflow(workflow.id);
            setWorkFlow(prev => {
                return { ...prev, editState: editState }
            })
            // setWorkFlow(prev => updated);
            setWorkflowUpdatedDate(updatedDate);

        }, 'Publish failed');
    }

    const handleWorkflowNameChange = ({ value }) => {
        setWorkFlow(prev => {
            return { ...prev, name: value };
        })
        debounceSaveWorkflow();
    }

    const debounceSaveWorkflow = useDebounceCallback(saveWorkflow, 1000);
    const shortDebounceSave = useDebounceCallback(saveWorkflow, 20);

    const loadData = async () => {
        setIsInitiallyLoaded(false);
        if (!workflowId) {
            return;
        }
        loadAction.execute(async () => {
            let { values, ...workflowDef } = await getWorkflowDefinition(workflowId);

            const convertedValues = convertToKvpValues(values);

            // if (workflowDef.workflowDefinitionState === workflowStates.active) {
            //     const { valuesUpdated, ...workflowDefUpdated } = await deactivateWorkflowDefinition(workflowDef.id)
            //     workflowDef = workflowDefUpdated;
            //     setWorkflowDisabledAlertOpen(true);

            // }

            if (workflowDef.workflowSteps && workflowDef.workflowSteps.length === 1) {
                const firstStep = workflowDef.workflowSteps[0];
                setActiveWorkflowItem(firstStep.id);
            }
            for (let index = 0; index < workflowDef.workflowSteps.length; index++) {
                const step = workflowDef.workflowSteps[index];
                const stepValues = convertedValues[step.id]
                await handleStepChanged(workflowDef, step, stepValues);

            }
            setWorkFlowValues(convertedValues);
            setWorkFlow(workflowDef);
            setWorkflowUpdatedDate(workflowDef.updatedDate)
            setIsInitiallyLoaded(true);
        }, "Failed to load")
    }

    const handleStepChange = async (step, workflowStepId) => {

        const idx = workflow.workflowSteps.findIndex(s => s.id == workflowStepId);

        setWorkFlow(prev => {
            prev.workflowSteps[idx] = step;
            return { ...prev, workflowSteps: [...prev.workflowSteps] };
        })
        debounceSaveWorkflow();
        handleStepChanged(workflow, step)
    }

    const handleStepChanged = async (workflowDef, step, valuesForStep = null) => {
        const workflowStepId = step.id;
        if (!valuesForStep) {
            valuesForStep = workflowValues[workflowStepId] || {};
        }

        let { appEvent, id, name, selectedEvent } = step;
        step = { appEvent, selectedEvent, id, name, values: convertValuesToArray(valuesForStep) }

        if (!appEvent || !selectedEvent) return;

        const stepPlaceHolders = extractPlaceholdersFromObject(valuesForStep, 3);
        const stepValues = await loadStepValues(workflowDef, step, valuesForStep);
        setWorkflowStepValues(prev => {
            const idx = prev.findIndex(x => x.workflowStepId === workflowStepId);
            const item = { workflowStepId, values: stepValues, stepPlaceHolders };
            if (idx === -1) {
                prev.push(item)
            } else {
                prev[idx] = item;
            }
            // const updated = _.clone(prev);
            return [...prev];
        });
    }

    const loadStepValues = async (workflowDef, step, valuesForStep = null) => {
        let valuePromises = [];

        if (step.selectedEvent?.loadStepValuesFromApi) {
            // const workflowValuesForStep = convertToListValues(workflowValues);
            // const filtered = workflowValuesForStep.filter(x => x.workflowStepId === step.id);
            const valuesAsList = convertKeyValueStepValuesToListValues(valuesForStep, step.id);
            const stepValues = await getStepValues(workflowDef.id, { ...step, values: valuesAsList });
            valuePromises.push(Promise.resolve(stepValues.map(e => mapDialogElementsToWorkflowElement(e))))
        } else if (step.appEvent?.type === workflowAppType.smartForm) {
            valuePromises = step.values.map((v, i) => {
                const valueSetupStep = step.selectedEvent?.setupSteps?.find(x => x.property === v.key);
                // specialApps.
                //Todo improve this logic
                if (valueSetupStep?.type === "nodes") {
                    return Promise.resolve([]);
                }
                if (v.key == "smartformId") {
                    // const valueSetupStep = step.selectedEvent.setupSteps?.find(x => x.property === "smartformId");
                    return new Promise(async (resolve, reject) => {
                        let elements = await getDialogDefinition(v.value.value, smartFormHelper.notFoundHandler(v.value.name, valuesForStep));
                        if (!elements) {
                            resolve([]);
                        } else {
                            const dialogElements = await getStepValues(workflowDef.id, step);
                            elements = [...dialogElements, ...elements];
                            const converted = elements.map(e => {
                                return mapDialogElementsToWorkflowElement(e);
                            });
                            resolve(converted)
                        }
                    })
                } else {
                    return Promise.resolve([v]);
                }

            });
        } else if (step.selectedEvent.useTest) {
            var apiResponseValues = step.values.find(x => x.key === "api-response")?.value;
            if (apiResponseValues) {
                valuePromises = Object.keys(apiResponseValues).map(k => {
                    const isArray = Array.isArray(apiResponseValues[k]);
                    return Promise.resolve({ key: k, value: { value: apiResponseValues[k], name: k, isArray } });
                });
            }
        }

        const values = (await Promise.all(valuePromises)).flat(1);
        return values;
    }

    const extractPlaceholdersFromObject = (obj, maxRecurseCount, currentIteration = 1) => {
        return Object.keys(obj).reduce((prev, curr,) => {
            const value = obj[curr];
            if (!value || currentIteration > maxRecurseCount) {
                return prev;
            }

            if (typeof value === 'string' || value instanceof String) {
                const placeHolders = getPlaceHolders(value);
                return [...prev, ...placeHolders];
            } else if (typeof value === 'object') {
                const placeHolders = extractPlaceholdersFromObject(value, maxRecurseCount, currentIteration + 1)
                return [...prev, ...placeHolders];
            }

            return prev;
        }, []);
    }

    const handleWorkflowStepNameChange = (workflowStepId, stepName) => {
        const stepIdx = workflow.workflowSteps.findIndex(x => x.id === workflowStepId);
        const step = workflow.workflowSteps[stepIdx];
        handleStepChanged(workflow, step);
        setWorkFlow(prev => {
            const i = prev.workflowSteps.findIndex(x => x.id === workflowStepId);
            prev.workflowSteps[i].name = stepName;
            return { ...prev, workflowSteps: [...prev.workflowSteps] };
        });
        debounceSaveWorkflow();

    }

    const handleAddNewStep = (workflowStepId, branchId) => {
        const workflowStep = {
            id: uuidv4(),
            name: "",
            stepType: workflowStepType.action,
            branchId: branchId
        };
        const stepIdx = workflowStepId ?
            workflow.workflowSteps.findIndex(ws => ws.id === workflowStepId) :
            workflow.workflowSteps.length;
        setWorkFlow(prev => {
            return { ...prev, workflowSteps: [...prev.workflowSteps.insert(stepIdx + 1, workflowStep)] };
        });

        setActiveWorkflowItem(workflowStep.id);
        debounceSaveWorkflow();
    }

    const handleCopyStep = (workflowStepId) => {
        const stepIdx = workflow.workflowSteps.findIndex(ws => ws.id === workflowStepId);
        if (stepIdx === -1) return;

        let copiedValue = null;
        if (workflowValues[workflowStepId]) {
            copiedValue = _.cloneDeep(workflowValues[workflowStepId]);

            setWorkFlowValues(prev => {
                return { ...prev, [copiedWorkflowStep.id]: copiedValue };
            });
        }

        const copiedWorkflowStep = _.cloneDeep(workflow.workflowSteps[stepIdx]);
        copiedWorkflowStep.id = uuidv4();
        copiedWorkflowStep.name = `(copy) ${copiedWorkflowStep.name}`;
        handleStepChanged(workflow, copiedWorkflowStep, copiedValue)
        setWorkFlow(prev => {
            return { ...prev, workflowSteps: [...prev.workflowSteps.insert(stepIdx + 1, copiedWorkflowStep)] };
        });

        setActiveWorkflowItem(copiedWorkflowStep.id);
        debounceSaveWorkflow();
    }

    const handleDeleteStep = (workflowStepId) => {
        if (validationErrors[workflowStepId]) {
            handleSetValidationError(workflowStepId, null);
        }

        const stepIdx = workflow.workflowSteps.findIndex(ws => ws.id === workflowStepId);
        if (stepIdx === -1) return;

        const removedStep = workflow.workflowSteps[stepIdx];

        if (removedStep.appEvent?.type === workflowAppType.branch || removedStep.appEvent?.type === workflowAppType.distribution) {
            const stepsInBranch = workflow.workflowSteps.filter(x => removedStep.workflowStepBranchIds.includes(x.branchId));
            handleStepConnectionChange(stepsInBranch, null);

            workflow.workflowBranches = workflow.workflowBranches.filter(x => !removedStep.workflowStepBranchIds.includes(x.id));
            workflow.workflowSteps = workflow.workflowSteps.filter(x => !removedStep.workflowStepBranchIds.includes(x.branchId));
        } else {
            handleStepConnectionChange([removedStep], null);
        }

        const branchSteps = workflow.workflowSteps.filter(ws => ws.branchId == removedStep.branchId);
        const branchStepIdx = branchSteps.findIndex(ws => ws.id === workflowStepId);
        const prevStep = workflow.workflowSteps[branchStepIdx - 1];
        setWorkFlowValues(prev => {
            return { ...prev };
        });

        setWorkFlow(prev => {

            return {
                ...prev,
                workflowSteps: [...prev.workflowSteps.filter(x => x.id !== workflowStepId)],
                workflowBranches: [...(prev.workflowBranches || [])]
            };
        })

        setActiveWorkflowItem(prevStep?.id || "");
        debounceSaveWorkflow();
    }

    const setWorkflowStepAppEvent = async (workflowStepId, appEvent) => {
        const stepIdx = workflow.workflowSteps.findIndex(x => x.id === workflowStepId);
        const step = workflow.workflowSteps[stepIdx];
        step.name = appEvent.name;
        step.appEvent = appEvent;
        step.selectedEvent = {};

        handleStepConnectionChange([step], null);
        step.oAuthAppConnectionId = null;

        handleStepChanged(workflow, step);

        setWorkFlow(prev => {
            // const stepIdx = prev.workflowSteps.findIndex(x => x.id === workflowStepId);
            prev.workflowSteps[stepIdx].appEvent = appEvent;
            prev.workflowSteps[stepIdx].selectedEvent = {};
            prev.workflowSteps[stepIdx].workflowStepBranchIds = [];
            prev.workflowSteps[stepIdx].oAuthAppConnectionId = '';
            return { ...prev, workflowSteps: [...prev.workflowSteps] };
        });
        setWorkFlowValues(prev => {
            return { ...prev, [workflowStepId]: {} };
        });
        debounceSaveWorkflow();
    }

    //Her må du resette alle dialogvalues.workflowRetrieveId til null hvis det er en trigger;
    const handleSetStepEvent = async (workflowStepId, event) => {
        console.log("Workflow values", workflowValues)
        const stepIdx = workflow.workflowSteps.findIndex(x => x.id === workflowStepId);
        const step = workflow.workflowSteps[stepIdx];
        if (!step.appEvent?.autoSelectEvent)
            step.name = `${event?.name} in ${step.appEvent?.name}`;

        handleAutoCreateFutureSteps(step, event);

        step.selectedEvent = event;
        handleStepChanged(workflow, step);

        setWorkFlow(prev => {
            const stepIdx = prev.workflowSteps.findIndex(x => x.id === workflowStepId);
            prev.workflowSteps[stepIdx].selectedEvent = event;
            return { ...prev, workflowSteps: [...prev.workflowSteps] };
        });
        setWorkFlowValues(prev => {
            let stepDefaulValues = {};
            event.setupSteps.forEach(step => {
                if (step.type === "nodes") {
                    stepDefaulValues = { ...stepDefaulValues, [step.property]: {} }
                }
            })
            return { ...prev, [workflowStepId]: stepDefaulValues };
        });
        //debugger
        debounceSaveWorkflow();
    }

    const handleAutoCreateFutureSteps = (step, event) => {
        if (!event.autoCreateFutureSteps?.length)
            return;

        const stepIdx = workflow.workflowSteps.findIndex(x => x.id === step.id);
        const nextSteps = workflow.workflowSteps.slice(stepIdx + 1);
        nextSteps.forEach(nextStep => {
            handleDeleteStep(nextStep.id);
        })

        for (const [index, futureStep] of event.autoCreateFutureSteps.entries()) {
            const appEvent = getWorkflowApp(futureStep.appEventType);
            const selectedEvent = getWorkflowAppEvent(futureStep.appEventType, futureStep.selectedEventKey);
            if (!appEvent || !selectedEvent)
                break;

            const workflowStep = {
                id: uuidv4(),
                name: appEvent.autoSelectEvent ? appEvent.name : `${selectedEvent.name} in ${appEvent.name}`,
                stepType: workflowStepType.action,
                branchId: step.branchId,
                appEvent: appEvent,
                selectedEvent: selectedEvent
            };

            workflow.workflowSteps.splice(stepIdx + index + 1, 0, workflowStep);
        }

        setWorkFlow(prev => {
            return { ...prev, workflowSteps: [...prev.workflowSteps] };
        });
    }

    const handleSetStepTestResult = (workflowStepId, testResult) => {
        setWorkFlow(prev => {
            const stepIdx = prev.workflowSteps.findIndex(x => x.id === workflowStepId);
            prev.workflowSteps[stepIdx].testResult = testResult;
            return { ...prev, workflowSteps: [...prev.workflowSteps] };
        });
    }

    const handleWorkflowStepValueChange = (workflowStepId, property, value, triggerStepChangedEvent = false) => {

        let hasChanged = false;
        const prevValue = workflowValues[workflowStepId]?.[property];
        if (prevValue && JSON.stringify(prevValue) !== JSON.stringify(value)) {
            hasChanged = true;
        }


        setWorkFlowValues(prev => {
            const stepValues = { ...(prev[workflowStepId] || {}), [property]: value };

            return { ...prev, [workflowStepId]: stepValues };
        });

        const placeholdersChanged = placeHoldersHaveChanged(concatObjectStringValues(prevValue), concatObjectStringValues(value))

        if (triggerStepChangedEvent || placeholdersChanged) {
            const stepValues = { ...(workflowValues[workflowStepId] || {}), [property]: value };
            const stepIdx = workflow.workflowSteps.findIndex(x => x.id === workflowStepId);
            const step = workflow.workflowSteps[stepIdx];
            handleStepChanged(workflow, step, stepValues)
        }
        debounceSaveWorkflow();
        return hasChanged;
    }

    const handleSetFilterGroups = (workflowStepId, filterGroups) => {
        setWorkFlow(prev => {
            const stepIdx = prev.workflowSteps.findIndex(x => x.id === workflowStepId);
            prev.workflowSteps[stepIdx].filterGroups = filterGroups;
            return { ...prev, workflowSteps: [...prev.workflowSteps] };
        })
        debounceSaveWorkflow();;
    }

    const handleAddBranch = (branch) => {
        const wfStep = workflow.workflowSteps.find(x => x.id === branch.stepId);
        const updateStepBranches = [...wfStep.workflowStepBranchIds, branch.id];
        const updated = [...workflow.workflowBranches || [], branch];
        setWorkFlow(prev => {
            prev.workflowBranches = updated;
            const stepIdx = prev.workflowSteps.findIndex(x => x.id === wfStep.id);
            prev.workflowSteps[stepIdx].workflowStepBranchIds = updateStepBranches;
            return { ...prev };
        });

        debounceSaveWorkflow();
    }

    const handleAddBranches = (branches, workflowStepId) => {

        const branchIds = branches.map(x => x.id);
        const wfStep = workflow.workflowSteps.find(x => x.id === workflowStepId);
        const updateStepBranches = [...wfStep.workflowStepBranchIds || [], ...branchIds];
        const updated = [...workflow.workflowBranches || [], ...branches];

        setWorkFlow(prev => {
            prev.workflowBranches = updated;
            const stepIdx = prev.workflowSteps.findIndex(x => x.id === workflowStepId);
            prev.workflowSteps[stepIdx].workflowStepBranchIds = updateStepBranches;
            return { ...prev };
        });

        debounceSaveWorkflow();
    }

    const handleUpdateBranch = (branch) => {
        if (!workflow.workflowBranches) {
            return;
        }

        const idx = workflow.workflowBranches.findIndex(x => x.id == branch.id);
        workflow.workflowBranches[idx] = branch;

        setWorkFlow(prev => {
            return { ...prev, workflowBranches: [...workflow.workflowBranches] };
        });
        const branchStep = workflow.workflowSteps.find(x => x.id === branch.stepId);

        handleStepChanged(workflow, branchStep);
        debounceSaveWorkflow();
    }

    const handleRemoveBranch = (branch) => {
        if (!workflow.workflowBranches) {
            return;
        }

        const stepsInBranch = workflow.workflowSteps.filter(x => x.branchId === branch.id);
        handleStepConnectionChange(stepsInBranch, null);

        const updated = [...workflow.workflowBranches.filter(b => b.id != branch.id)];
        const stepIdx = workflow.workflowSteps.findIndex(x => x.id === branch.stepId);

        workflow.workflowSteps[stepIdx].workflowStepBranchIds = workflow.workflowSteps[stepIdx].workflowStepBranchIds.filter(x => x != branch.id);
        setWorkFlow(prev => {
            return { ...prev, workflowBranches: updated, workflowSteps: [...workflow.workflowSteps.filter(x => x.branchId !== branch.id)] };
        });

        debounceSaveWorkflow();
    }

    const convertToKvpValues = (valuesList) => {
        const out = valuesList.reduce((acc, curr, i) => {
            acc[curr.workflowStepId] = { ...acc[curr.workflowStepId], [curr.property]: curr.value };
            return acc;
        }, {})
        return out;
    }

    const handleSetValidationError = (workflowStepId, error, isInitialized = true) => {
        const currError = validationErrorsRef.current[workflowStepId];
        if (JSON.stringify(currError) === JSON.stringify(error)) {
            return;
        }

        if (error) {
            validationErrorsRef.current[workflowStepId] = error;
        } else {
            delete validationErrorsRef.current[workflowStepId];
        }

        setValidationErrors(prev => {
            if (error) {
                return { ...prev, [workflowStepId]: error };
            } else {
                delete prev[workflowStepId];
                return { ...prev };
            }
        })

        if (isInitialized) {
            debounceSaveWorkflow();
        }

    }

    const onBranchModalClose = () => {
        setBranchEditModalOpen(false);
        setEditBranch(null);
        setWorkFlowValues(prev => ({ ...prev }));
    }

    const handleUpdateStepTestValue = (workflowStepId, value) => {
        setWorkflowTestValues(prev => {
            if (value) {
                return { ...prev, [workflowStepId]: value }
            } else {
                delete prev[workflowStepId];
                return { ...prev };
            }
        });

        debounceSaveWorkflow();
    }

    const mapDialogElementsToWorkflowElement = (dialogElement) => {
        return { key: dialogElement.property, value: { value: dialogElement.property, name: dialogElement.property, isArray: dialogElement.isArray ? true : false } };
    }

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const activeWorkflowStepIndex = workflow.workflowSteps?.findIndex(ws => ws.id === active.id);
            const overWorkflowStepIndex = workflow.workflowSteps?.findIndex(ws => ws.id === over.id);
            if (activeWorkflowStepIndex !== -1 &&
                overWorkflowStepIndex !== -1 &&
                canStepMove(workflow.workflowSteps[overWorkflowStepIndex])) {
                workflow.workflowSteps.splice(overWorkflowStepIndex, 0, workflow.workflowSteps.splice(activeWorkflowStepIndex, 1)[0]);

                handleSetWorkflow(workflow);

                if (activeWorkflowStepIndex <= overWorkflowStepIndex) {
                    dragAndDropChangedStepsRef.current = workflow.workflowSteps.slice(activeWorkflowStepIndex, overWorkflowStepIndex + 1).map(ws => ws.id);
                } else {
                    dragAndDropChangedStepsRef.current = workflow.workflowSteps.slice(overWorkflowStepIndex, activeWorkflowStepIndex + 1).map(ws => ws.id);
                }

                debounceSaveWorkflow();
            }
        }
    }

    const updateStepIdxInValues = (steps) => {
        const clonedWorkflowValues = _.cloneDeep(workflowValues);
        for (const stepId in clonedWorkflowValues) {
            for (const property in clonedWorkflowValues[stepId]) {
                for (const key in clonedWorkflowValues[stepId][property]) {
                    if (typeof clonedWorkflowValues[stepId][property][key] === 'string' && clonedWorkflowValues[stepId][property][key].includes("stepIdx")) {
                        clonedWorkflowValues[stepId][property][key] = updateStepIdxInPlaceholder(clonedWorkflowValues[stepId][property][key], steps);
                    }
                }
            }
        }
        if (JSON.stringify(clonedWorkflowValues) !== JSON.stringify(workflowValues)) {
            setWorkFlowValues(clonedWorkflowValues);
        }
    }

    const updateStepIdxInTriggerFilterGroupsOfBranch = (branches, steps) => {
        branches?.forEach(branch => {
            branch.triggerFilterGroups?.forEach(group => {
                group.groupFilters?.forEach(filter => {
                    if (filter.field?.stepIdx && filter.field?.stepId) {
                        const newStepIdx = steps?.findIndex(ws => ws.id === filter.field.stepId);
                        if (newStepIdx !== -1 && newStepIdx !== filter.field.stepIdx) {
                            filter.field.stepIdx = newStepIdx;
                        }
                    }
                })
            });
        })
    }

    const updateStepIdxInFilterSteps = (steps) => {
        steps.forEach(step => {
            step.filterGroups?.forEach(group => {
                group.groupFilters?.forEach(filter => {
                    if (filter.field?.stepIdx && filter.field?.stepId) {
                        const newStepIdx = steps?.findIndex(ws => ws.id === filter.field.stepId);
                        if (newStepIdx !== -1 && newStepIdx !== filter.field.stepIdx) {
                            filter.field.stepIdx = newStepIdx;
                        }
                    }
                })
            })
        })
    }

    const handleSetWorkflow = (value) => {
        const clonedWorkflow = _.cloneDeep(value);

        // update stepIdx in placeholders of values
        updateStepIdxInValues(clonedWorkflow.workflowSteps);

        // update stepIdx in triggerFilterGroup of branches
        updateStepIdxInTriggerFilterGroupsOfBranch(clonedWorkflow.workflowBranches, clonedWorkflow.workflowSteps);

        // update stepIdx in filter steps
        updateStepIdxInFilterSteps(clonedWorkflow.workflowSteps);

        setWorkFlow(clonedWorkflow);
    }

    const handleStepConnectionChange = async (stepsToDelete, connectionIdToUpdate) => {
        const otherSteps = workflow.workflowSteps.filter(x => !stepsToDelete.find(ds => ds.id === x.id));
        const otherConnectionIds = otherSteps.filter(x => x.oAuthAppConnectionId).map(x => x.oAuthAppConnectionId);
        const cIdsToDelete = stepsToDelete.filter(x => x.oAuthAppConnectionId && !otherConnectionIds.includes(x.oAuthAppConnectionId))
            .map(x => x.oAuthAppConnectionId);

        let cIdToUpdate = workflow.workflowSteps.find(x => x.oAuthAppConnectionId === connectionIdToUpdate) ? null : connectionIdToUpdate;

        if (cIdsToDelete.length === 0 && !cIdToUpdate)
            return;

        await updateOAuthAppConnectionWorkflowIds({
            connectionIdsToDelete: cIdsToDelete,
            connectionIdToUpdate: cIdToUpdate,
            WorkflowDefinitionId: workflow.id,
        });
    }

    const handleActivateWorkflow = async () => {
        const errors = convertToListErrors(validationErrors);
        const warnings = workflow.errors.filter(e => e.type === "warning");
        if (errors.length > 0) {
            alertError("Workflow contains errors, please review workflow",
                () => { },
                false
            )
        } else if (warnings.length > 0) {
            alertWarn("Workflow contains warnings, still activate?", () => activateWorkflow(workflow), true, "Cancel", () => { }, "Activate");
        } else {
            activateWorkflow(workflow);
        }

    }

    const handleDeactivateWorkflow = async () => {
        activateAction.execute(async () => {
            const { updatedDate } = await deactivateWorkflowDefinition(workflow.id);
            setWorkFlow(prev => {
                return { ...prev, workflowDefinitionState: workflowStates.inactive }
            })
            setWorkflowUpdatedDate(updatedDate)
        }, "Failed to disable")
    }

    const activateWorkflow = async (workflowDef) => {
        activateAction.execute(async () => {
            const { updatedDate } = await activateWorkflowDefinition(workflowDef.id);
            setWorkFlow(prev => {
                return { ...prev, workflowDefinitionState: workflowStates.active }
            })
            setWorkflowUpdatedDate(updatedDate)
        }, "Activate failed");
    }

    useEffect(() => {
        loadData();
        // return () => {
        //     // console.log('removing workflow in layoutcontext')
        //     adminLayoutContext.setCurrentWorkflow(null);
        // }
    }, []);

    useEffect(() => {
        // console.log(workflow);
        // console.log(validationErrors);
        //console.log('setting workflow in layoutcontext', workflow)
        adminLayoutContext.setCurrentWorkflow(workflow);
    }, [workflow]);

    return workflow ? (
        <WorkflowContext.Provider value={{
            workFlow: workflow,
            validationErrors: validationErrors,
            activeStepId: activeWorkflowItem,
            testValues: workflowTestValues,
            saving: false,
            workflowStepValues: workflowStepValues,
            mapDialogElementsToWorkflowElement: mapDialogElementsToWorkflowElement,
            deleteStep: (workflowStepId) => {
                handleDeleteStep(workflowStepId);
            },
            addStep: (workflowStepId, branchId) => {
                handleAddNewStep(workflowStepId, branchId);
            },
            copyStep: (workflowStepId) => {
                handleCopyStep(workflowStepId);
            },
            setStepAppEvent: (workflowStepId, appEvent) => {
                setWorkflowStepAppEvent(workflowStepId, appEvent);
            },
            setStepEvent: (workflowStepId, event) => {
                handleSetStepEvent(workflowStepId, event);
            },
            updateWorkflowStepName: (workflowStepId, stepName) => {
                handleWorkflowStepNameChange(workflowStepId, stepName)
            },
            setActiveWorkflowStep: (workflowStepId) => {
                setActiveWorkflowItem(workflowStepId);
            },
            setFilterGroups: (workflowStepId, filterGroups) => {
                handleSetFilterGroups(workflowStepId, filterGroups);
            },
            updateWorkflowStep: (step, workflowStepId) => {
                handleStepChange(step, workflowStepId);
            },
            addBranch: (branch) => {
                handleAddBranch(branch);
            },
            addBranches: (branches, workflowStepId) => {
                handleAddBranches(branches, workflowStepId);
            },
            updateBranch: (branch) => {
                handleUpdateBranch(branch);
            },
            removeBranch: (branch, stepId) => {
                handleRemoveBranch(branch);
            },
            setEditBranch: (branch) => {
                setEditBranch(branch);
                setBranchEditModalOpen(true);
            },
            setValidationError: (workflowStepId, error, isInitialized) => {
                handleSetValidationError(workflowStepId, error, isInitialized);
            },
            setStepTestResult: (workflowStepId, testResult) => {
                handleSetStepTestResult(workflowStepId, testResult);
            },
            updateStepTestValue: (workflowStepId, value) => {
                handleUpdateStepTestValue(workflowStepId, value);
            },
            extractPlaceholdersFromObject: extractPlaceholdersFromObject,
            dragAndDropDisabled: dragAndDropDisabled,
            setDragAndDropDisabled: setDragAndDropDisabled,
            dragAndDropChangedStepsRef: dragAndDropChangedStepsRef,
            handleDragEnd: (event) => {
                handleDragEnd(event);
            },
            handleStepConnectionChange: (stepsToDelete, connectionIdToUpdate) => {
                handleStepConnectionChange(stepsToDelete, connectionIdToUpdate);
            },
        }}>
            <WorkflowValuesContext.Provider value={{
                workFlowValues: workflowValues,
                updateWorkflowStepValue: (workflowStepId, property, value, triggerStepChangedEvent = false) => {
                    return handleWorkflowStepValueChange(workflowStepId, property, value, triggerStepChangedEvent);
                },
                getWorkflowStepValue: (workflowStepId, property) => {
                    return workflowValues[workflowStepId]?.[property];
                },
                setWorkFlowValues: setWorkFlowValues,
                handleStepChanged: handleStepChanged,
                debounceSaveWorkflow: debounceSaveWorkflow
            }}>
                <SidebarContext.Provider
                    value={{
                        publishDisabled: publishDisabled,
                        onPublish: () => {
                            handlePublish()
                        },
                        onDisable: () => {
                            handleDeactivateWorkflow();
                        },
                        onActivate: () => {
                            handleActivateWorkflow();
                        }
                    }}
                >
                    <div className="flex-1 flex w-full h-full gap-12 mt-4 max-w-screen-2xl">
                        <main className='flex-1 h-mobile-content lg:h-sm-content relative overflow-y-auto'>
                            <div className="flex min-h-4/5 flex-col px-2 pb-4">
                                <div className="flex-1 bg-gray-50 border border-gray-200 sm:rounded-lg">
                                    <div className="max-w-4xl mx-auto h-full flex flex-col pt-16 pb-6 px-8 lg:px-0">
                                        {workflowValuesId ?
                                            <WorkflowRunView workflowId={workflowId} workflowValuesId={workflowValuesId} />
                                            :
                                            <>
                                                <div className="pb-4 mb-4 border-b border-gray-200 max-w-4xl sm:flex sm:justify-between sm:flex-row-reverse">
                                                    <div className="mb-2 sm:mb-0 text-right">
                                                        <WorkflowDefinitionStateBadge state={workflow.workflowDefinitionState} />
                                                    </div>
                                                    <h3 className="text-lg leading-6 font-medium text-gray-900 max-w-xl sm:w-4/6">
                                                        <div className="flex rounded-md hover:shadow-sm">
                                                            <input
                                                                type="text"
                                                                name="trigger-name"
                                                                id="trigger-name"
                                                                autoComplete="off"
                                                                // ref={triggerNameInputRef}
                                                                className={
                                                                    classNames("text-lg flex-1 py-1 bg-transparent hover:bg-white focus:bg-white font-semibold focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-l-md border-transparent hover:border-gray-300",
                                                                        nameHovered ? "bg-white border-gray-300" : "")
                                                                }
                                                                value={workflow.name}
                                                                ref={nameInputRef}
                                                                onFocus={e => {
                                                                    setIsEditingName(true);
                                                                }}
                                                                onMouseEnter={() => setNameHovered(true)}
                                                                onMouseLeave={() => setNameHovered(false)}
                                                                onChange={e =>
                                                                    handleWorkflowNameChange(e.target)
                                                                }
                                                                onBlur={e => {
                                                                    // workflowContext.updateWorkflowStepName(workflowStep.id, stepName);
                                                                    // setIsRenaming(false)
                                                                    setIsEditingName(false);
                                                                    // setNameHovered(false);
                                                                }}
                                                            />
                                                            {
                                                                (isEditingName || nameHovered) && (<span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                                                                    onMouseEnter={() => setNameHovered(true)}
                                                                    onClick={e => {
                                                                        setNameInputRef();
                                                                    }}
                                                                >
                                                                    <PencilIcon className="h-5 w-5" />
                                                                </span>)
                                                            }
                                                        </div>
                                                    </h3>
                                                </div>
                                                <DndContextWrapper
                                                    sortableItems={sortableItems}
                                                >
                                                    {
                                                        getSteps(workflow).map((ws, i) => {
                                                            return (
                                                                <DndSortable
                                                                    key={i.toString() + (ws?.id || "new")}
                                                                    id={ws.id}
                                                                    isSortable={canStepMove(ws)}
                                                                    disabled={dragAndDropDisabled}
                                                                >
                                                                    <WorkFlowStep
                                                                        workflowStep={ws}
                                                                        stepCount={workflow.workflowSteps.filter(ws => !ws.branchId).length}
                                                                        stepIdx={i}
                                                                        activeStep={activeWorkflowItem === ws.id} />
                                                                </DndSortable>
                                                            )
                                                        })
                                                    }
                                                </DndContextWrapper>
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </main>
                        <aside className='hidden xl:flex xl:flex-col flex-shrink-0 w-1/5 shadow-xl'>
                            <Sidebar />
                        </aside>
                    </div>
                </SidebarContext.Provider>
                <GenericModal open={workflowDisabledAlertOpen} setOpen={setWorkflowDisabledAlertOpen} onConfirm={() => setWorkflowDisabledAlertOpen(false)} confirmButtonText={"Ok"} showCancelButton={false} title={"Info"}>
                    <div className='py-4'>
                        Workflow now disabled
                    </div>
                </GenericModal>
                <FullScreenModal
                    open={branchEditModalOpen} setOpen={setBranchEditModalOpen}
                >
                    <WorkflowBranchEditor branch={editBranch} onCloseModal={() => {
                        onBranchModalClose();
                    }} />
                </FullScreenModal>
            </WorkflowValuesContext.Provider>
        </WorkflowContext.Provider>
    ) : (<>Loading your Workflow...</>)
}