import { get, post, destroy, BaseUrl, put } from './api';

const controller = "WorkflowTable";

export const getWorkflowTables = async () => {
    return await get(controller, null, BaseUrl.WorkFlow);
}

export const getWorkflowTableDefinition = async (workflowTableId) => {
    return await get(`${controller}/${workflowTableId}/definition`, null, BaseUrl.WorkFlow);
}

export const getWorkflowTableData = async (workflowTableId, cols, ops, vals, take, skip) => {
    return await get(`${controller}/${workflowTableId}/data?cols=${cols}&ops=${ops}&vals=${vals}&take=${take}&skip=${skip}`, null, BaseUrl.WorkFlow);
}

export const getWorkflowTableFieldTypes = async () => {
    return await get(`${controller}/fieldtypes`, null, BaseUrl.WorkFlow);
}

export const createWorkflowTable = async (name, description, fields) => {
    return await post(`${controller}/definition`, { name, description, fields }, null, BaseUrl.WorkFlow);
}

export const updateWorkflowTableDefinition = async (id, name, description, fields) => {
    return await put(`${controller}/definition`, { id, name, description, fields }, null, BaseUrl.WorkFlow);
}

export const deleteWorkflowTableData = async (workflowTableId, dataRowId) => {
    return await destroy(`${controller}/${workflowTableId}/data/${dataRowId}`, null, BaseUrl.WorkFlow);
}

export const destroyWorkflowTable = async (workflowTableId) => {
    return await destroy(`${controller}/${workflowTableId}`, null, BaseUrl.WorkFlow);
}