import { ButtonV2, CoreButtonTypes } from '@metaforcelabs/metaforce-core';
import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { WorkflowStepContext } from "../../../../../contexts";
import { NodeItem } from "./Components/nodeItem";
import { getWorkflowTables } from '../../../../../api/workflowTables';
import { useTableFilterHelper } from '../../../../../hooks/useTableFilterHelper';
import { workflowTableFieldType } from "utils/constants";
import { Select } from "../../../../../components/Form/Select";
import { TrashIcon } from "@heroicons/react/outline";

export const TableFiltersSetupActionStep = ({ workflowStepValues, setupActionStep, onStepValueChange, apiParameters, workflowStep }) => {
    const [filterableProperties, setFilterableProperties] = useState([]);
    const [filters, setFilters] = useState(workflowStepValues[setupActionStep.property]?.value || []);
    const [nodeValues, setNodeValues] = useState({})
    const stepContext = useContext(WorkflowStepContext);
    const tableFilterHelper = useTableFilterHelper();

    const handleNodeValueChange = (nodeId, value) => {
        onStepValueChange(nodeId, { value })
    }

    const loadData = async () => {
        if (apiParameters.length === 1) {
            const activeTables = await getWorkflowTables();
            const newFilterableProperties = tableFilterHelper.getFilterableProperties(activeTables.find(t => t.id == workflowStepValues.tableId.value));
            const newFilters = filters.filter(f => (f.property == null) || newFilterableProperties.find(p => p.id == f.property));
            setFilterableProperties(newFilterableProperties);
            setFilters(newFilters);
        }
    }

    useEffect(() => {
        const propValues = workflowStepValues[setupActionStep.property];

        if (propValues) {
            setNodeValues(propValues);
        }

    }, [workflowStepValues]);

    useEffect(() => {
        loadData();
    }, [apiParameters]);

    const setStepValue = (filters) => {
        setFilters(filters);
        handleNodeValueChange(setupActionStep.property, filters);
    }
    
    return (
        <div className='mt-4'>
            {filters.map((f, i) => (
                <React.Fragment key={"filter-property-" + i.toString()}>
                    <div className="col-span-1">
                        <h4 className="mt-4 mb-2 font-semibold">{`Field Name ${i + 1}:`}</h4>
                        <Select
                            id={"filter-property-" + i.toString()}
                            name={"filter-property-" + i.toString()}
                            className=""
                            options={[
                                {
                                    name: '- Select -',
                                    value: ''
                                },
                                ...tableFilterHelper.getFilterPropetyOptions(filterableProperties, filters, i)
                            ]}
                            onChange={value => {setStepValue(tableFilterHelper.filterOperationSetValue(filters, i, tableFilterHelper.getFilterPropetyOnchangeValue(filterableProperties, value)))}}
                            selectedValue={filters[i].property}
                        />
                    </div>
                    <div className="col-span-1">
                        {filters[i].property && (
                            <>
                                <h4 className="mt-4 mb-2 font-semibold">{`Type ${i + 1}:`}</h4>
                                <Select
                                    id={"filter-type-" + i.toString()}
                                    name={"filter-type-" + i.toString()}
                                    className=""
                                    options={[
                                        {
                                            name: '- Select -',
                                            value: ''
                                        },
                                        ...tableFilterHelper.getFilterTypeOptions(filters[i])
                                    ]}
                                    onChange={value => {setStepValue(tableFilterHelper.filterOperationSetValue(filters, i, {type: value}))}}
                                    selectedValue={filters[i].type}
                                />
                            </>
                        )}
                    </div>
                    <h4 className="mt-4 mb-2 font-semibold">{`Value ${i + 1}:`}</h4>                                   
                    <div className="flex flex-row justify-end mb-8">
                        {filters[i].property && (
                            <div className="flex-grow">
                                {[workflowTableFieldType.status, workflowTableFieldType.boolean].includes(filters[i].fieldType) ? (
                                    <Select
                                        id={"filter-value-" + i.toString()}
                                        name={"filter-value-" + i.toString()}
                                        className="capitalize"
                                        options={[
                                            {
                                                name: '- Select -',
                                                value: ''
                                            },
                                            ...tableFilterHelper.getFilterValueOptions(filters[i])
                                        ]}
                                        onChange={value => {setStepValue(tableFilterHelper.filterOperationSetValue(filters, i, {value: value}))}}
                                        selectedValue={filters[i].value}
                                    />
                                ): (
                                    <NodeItem
                                        item={{ id: "filter-value-" + i.toString() }}
                                        nodeValue={filters[i].value}
                                        previousSteps={stepContext.previousSteps}
                                        stepIdx={stepContext.stepIdx}
                                        workflowStep={workflowStep}
                                        previousStepsValues={stepContext.previousStepsValues}
                                        handleNodeValueChange={(nodeId, value) => {setStepValue(tableFilterHelper.filterOperationSetValue(filters, i, {value: value}))}}
                                    />
                                )}
                            </div>
                        )}
                        <button
                            className="group flex rounded-md items-center px-2 py-2 text-sm text-gray-700"
                            onClick={e => {
                                const newFilters = tableFilterHelper.filterOperationRemove(filters, i);
                                setStepValue(newFilters);
                            }}
                        >
                            <TrashIcon
                                className="w-5 h-5 mr-2"
                                aria-hidden="true"
                            />
                        </button>  
                    </div>
                </React.Fragment>
            ))}
            {filterableProperties.length > 0 && (
                <div className="mt-4">
                    <ButtonV2
                        label={"Add filter"}
                        type={CoreButtonTypes.white}
                        onClick={() => setStepValue(tableFilterHelper.filterOperationAdd(filters))}
                        disabled={filters.filter(f => !f.valid).length > 0}
                    />
                </div>
            )}
        </div>
    );

}