import { NO_STEP_EVENT_ALLOWED } from "../../../hooks/useWorkflowHelper";
import { viewpointProperties, workflowAppType } from "../../../utils/constants";
import { getParagrphPlaceHolders, getPlaceHolders } from "../../../utils/slate";
import { placeHolderExists } from "./utils";

const validateNodeValue = (nodeValue, previousStepsValues) => {
    if (nodeValue) {
        const placeHolderMatches = getParagrphPlaceHolders(nodeValue);

        return placeHolderMatches.every(match => {
            const placeHolder = JSON.parse(match[1]);
            return placeHolderExists(previousStepsValues, placeHolder);
        });
    }
    return true;
}

const checkIfDisplaySetupStep = (setupStep, workflowStepValues) => {
    if (setupStep.displayCondition) {
        const propValue = workflowStepValues[setupStep.displayCondition.property]?.value;
        if (!propValue || propValue !== setupStep.displayCondition.value)
            return false;
    }
    return true;
}

const validateAtLeastOneRequired = (eventSteps, workflowStepValues) => {
    const dict = eventSteps.filter(e => e.atLeastOneRequired).reduce((acc, curr) => {
        acc[curr.atLeastOneRequired] = acc[curr.atLeastOneRequired] ? [...acc[curr.atLeastOneRequired], curr] : [curr];
        return acc;
    }, {});

    const setupErrors = Object.values(dict).map(atLeastOneEventSteps => {
        const result = atLeastOneEventSteps.some(e => {
            if (!workflowStepValues)
                return false;

            const isDisplayed = checkIfDisplaySetupStep(e, workflowStepValues);
            if (!isDisplayed)
                return false;

            const nodeValues = workflowStepValues[e.property];
            if (!nodeValues)
                return false;

            switch (e.type) {
                case "input-field":
                    return !!nodeValues.value;
                case "nodes":
                case "input-list":
                case "input-dictionary":
                    return Object.values(nodeValues).find(nv => !!nv);
                default:
                    return true;
            }
        });

        if (!result) {
            return { type: "error", error: "At least one field is required.", errorField: atLeastOneEventSteps[0].property };
        }

        return null;
    }).filter(x => !!x);

    return setupErrors;
}

const validateSetupSteps = (eventSteps, workflowStepValues, previousStepsValues) => {
    const setupErrors = eventSteps.map(e => {
        // debugger;
        if (!workflowStepValues) {
            return { type: "error", error: "Setup Action Incomplete", errorField: e.property };
        }

        const isDisplayed = checkIfDisplaySetupStep(e, workflowStepValues);

        const nodeValues = workflowStepValues[e.property];
        if (e.required && isDisplayed && !nodeValues) {
            return { type: "error", error: "Setup Action Incomplete", errorField: e.property };
        }

        if (e.type === "nodes") {
            if (!nodeValues) {
                return { type: "error", error: "Setup Action Incomplete", errorField: e.property };
            }
            const nodesValid = Object.keys(nodeValues).every(k => {
                const nodeValue = nodeValues[k];
                return validateNodeValue(nodeValue, previousStepsValues);
            });
            if (!nodesValid) {
                return { type: "warning", error: "Placeholders missing ref", errorField: e.property }
            }
        }

        if (e.type === "input-field") {
            if (e.required && isDisplayed && !nodeValues?.value) {
                return { type: "error", error: "Setup Action Incomplete", errorField: e.property };
            }

            const nodesValid = validateNodeValue(nodeValues?.value, previousStepsValues);
            if (!nodesValid && isDisplayed) {
                return { type: "warning", error: "Placeholders missing ref", errorField: e.property }
            }

            if (e.sameStepAsProperty) {
                const comparedPropertyValue = workflowStepValues[e.sameStepAsProperty];
                if (comparedPropertyValue) {
                    const placeHolders = getPlaceHolders(nodeValues.value);
                    const comparedPlaceHolders = getPlaceHolders(comparedPropertyValue.value);
                    if (placeHolders.length === 1 && comparedPlaceHolders.length === 1 && placeHolders[0].stepId !== comparedPlaceHolders[0].stepId) {
                        return { type: "error", error: "StepId Mismatch", errorField: e.property };
                    }
                }
            }
        }

        if (e.type === "input-list") {
            if (e.required && (!nodeValues || !Object.values(nodeValues).find(nv => !!nv))) {
                return { type: "error", error: "Setup Action Incomplete", errorField: e.property };
            }

            if (nodeValues) {
                const nodesValid = Object.values(nodeValues).every(k => {
                    const nodeValue = nodeValues[k];
                    return validateNodeValue(nodeValue, previousStepsValues);
                });
                if (!nodesValid) {
                    return { type: "warning", error: "Placeholders missing ref", errorField: e.property }
                }
            }
        }

        if (e.type === "api-options") {
            if (e.property === "smartformId" && nodeValues?.value && nodeValues?.valid === false) {
                return { type: "error", error: "Smartform Not Found", errorField: e.property };
            }
        }

        if (e.type === "viewpoint-metadata-setup") {
            const requiredColumnsValue = workflowStepValues[viewpointProperties.requiredColumns];
            if (requiredColumnsValue) {
                const requiredColumnsValid = Object.values(requiredColumnsValue).every(requiredColumn => {
                    const nodeValue = workflowStepValues[requiredColumn];
                    if (!nodeValue || !nodeValue.value)
                        return false;
                    return validateNodeValue(nodeValue.value, previousStepsValues);
                });
                if (!requiredColumnsValid) {
                    return { type: "error", error: "Viewpoint Metadata Setup Action Incomplete", errorField: e.property };
                }
            }
        }

        return null;
    }).filter(x => !!x);

    const atLeastOneRequiredErrors = validateAtLeastOneRequired(eventSteps, workflowStepValues);

    return setupErrors.concat(atLeastOneRequiredErrors);
}

const validateStandardAppType = (workflowStep, workflowValues, previousStepsValues, isLastStep) => {
    let errors = [];
    if (!workflowStep.selectedEvent || !workflowStep.selectedEvent?.setupSteps) {
        errors.push({ type: "error", error: "No selected event", errorField: "selectedEvent" });
    }

    if (workflowStep.selectedEvent?.allowedFutureStepEvents?.includes(NO_STEP_EVENT_ALLOWED) && !isLastStep) {
        errors.push({ type: "error", error: "Can only be last step", errorField: "selectedEvent.allowedFutureStepEvents" });
    }

    const setupStepErrors = validateSetupSteps(workflowStep?.selectedEvent?.setupSteps || [], workflowValues[workflowStep.id], previousStepsValues);
    if (workflowStep.selectedEvent?.useTest) {
        if (!workflowStep.testResult || !workflowStep.testResult?.isSuccess) {
            errors.push({ type: "error", error: "Test incomplete or failed", errorField: "testResult" });
        }
    }

    if (setupStepErrors.length) {
        errors = [...errors, ...setupStepErrors];
    }

    return errors;
}

const validateFilterGroups = (filterGroups, previousStepsValues) => {
    return filterGroups.length > 0 && filterGroups.every(x => {
        return x.groupFilters.some(gf => {
            const s = !!gf.condition && placeHolderExists(previousStepsValues, gf.field);
            return s;
        })
    })
}
const validateFilterAppType = (workflowStep, workflowValues, previousStepsValues) => {
    let errors = [];
    const groupsValid = validateFilterGroups(workflowStep.filterGroups || [], previousStepsValues);
    if (!groupsValid) {
        errors.push({ type: "error", error: "Condition incomplete", errorField: "groupFilters" })
    }

    return errors;
}

const validateLoopAppType = (workflowStep, workflowBranches, previousStepsValues, allSteps, stepErrors) => {
    let errors = [];
    // debugger;
    var branches = workflowStep.workflowStepBranchIds?.map(wbid => workflowBranches.find(x => x.id === wbid));

    const hasOneBranch = branches && branches.length === 1;
    if (!hasOneBranch) {
        errors.push({ type: "error", error: "One loop required", errorField: "workflowStepBranchIds" })
        return errors;
    }

    var loopBranch = branches[0];

    var loopConfig = loopBranch?.loopBranchConfig;

    if (!loopConfig) {
        errors.push({ type: "error", error: "Loop config missing", errorField: "loopConfig" })
        return errors;
    }

    if (!loopConfig.field) {
        errors.push({ type: "error", error: "Loop config field missing", errorField: "loopConfig.field" })
    }

    if (!loopConfig.name) {
        errors.push({ type: "error", error: "Loop config name missing", errorField: "loopConfig.name" })
    }

    const branchIds = branches.map(b => b.id);

    const branchSteps = allSteps.filter(x => branchIds.includes(x.branchId));

    const stepsWithError = branchSteps.map(x => stepErrors[x.id]).filter(error => !!error && error.length > 0);

    if (stepsWithError.length && stepsWithError.length > 0) {
        errors.push({ type: "error", error: "Loop Steps With Error", errorField: "workflowStepBranchIds" })
    }

    return errors;
}
const validateBranchAppType = (workflowStep, workflowBranches, previousStepsValues, allSteps, stepErrors) => {
    let errors = [];
    // debugger;
    var branches = workflowStep.workflowStepBranchIds?.map(wbid => workflowBranches.find(x => x.id === wbid));
    const branchTriggersValid = branches && branches?.every(b => {
        return validateFilterGroups(b?.triggerFilterGroups || [], previousStepsValues);
    })

    if (!branchTriggersValid) {
        errors.push({ type: "error", error: "Branch triggers missing", errorField: "workflowStepBranchIds" })
        return errors;
    }

    const branchIds = branches.map(b => b.id);
    if (branchTriggersValid) {
        const branchSteps = allSteps.filter(x => branchIds.includes(x.branchId));
        const stepsWithError = branchSteps.map(x => stepErrors[x.id]).filter(error => !!error && error.length > 0);
        if (stepsWithError.length && stepsWithError.length > 0) {
            errors.push({ type: "error", error: "Branch Steps With Error", errorField: "workflowStepBranchIds" })
        }
    }
    return errors;
}

const validateOAuthAppConnectionAppType = (workflowStep, workflowValues, previousStepsValues) => {
    let errors = [];
    if (!workflowStep.oAuthAppConnectionId && workflowStep.selectedEvent?.useAccountSelect) {
        errors.push({ type: "error", error: "No selected connection", errorField: "accountSelect" });
    }

    errors = [...errors, ...validateStandardAppType(workflowStep, workflowValues, previousStepsValues)];

    return errors;
}

export const stepValidator = (workflowStep, workflowValues, previousStepsValues, workflowBranches, allSteps, stepErrors, isLastStep) => {
    let errors = []

    if (!workflowStep.appEvent) {
        errors.push({ type: "error", error: "No App Event", errorField: "appEvent" });
    }

    if (workflowStep.appEvent?.type === workflowAppType.branch) {
        // debugger;
    }

    if (workflowStep.appEvent) {
        switch (workflowStep.appEvent.type) {
            case workflowAppType.filter: {
                errors = [...errors, ...validateFilterAppType(workflowStep, workflowValues, previousStepsValues)]
                break;
            }
            case workflowAppType.branch: {
                errors = [...errors, ...validateBranchAppType(workflowStep, workflowBranches, previousStepsValues, allSteps, stepErrors)]
                break;
            }
            case workflowAppType.distribution: {
                errors = [...errors, ...validateBranchAppType(workflowStep, workflowBranches, previousStepsValues, allSteps, stepErrors)]
                break;
            }
            case workflowAppType.loop: {
                errors = [...errors, ...validateLoopAppType(workflowStep, workflowBranches, previousStepsValues, allSteps, stepErrors)]
                break;
            }
            case workflowAppType.microsoftOutlook:
            case workflowAppType.kivra:
            case workflowAppType.digipost:
            case workflowAppType.sftp:
            case workflowAppType.viewpoint: {
                errors = [...errors, ...validateOAuthAppConnectionAppType(workflowStep, workflowValues, previousStepsValues)]
                break;
            }
            default: {
                errors = [...errors, ...validateStandardAppType(workflowStep, workflowValues, previousStepsValues, isLastStep)]
                break;
            }
        }
    }
    return errors;
}