import { ButtonV2, CoreButtonTypes } from "@metaforcelabs/metaforce-core";
import Modal from "../../../../components/Modals/modal";

export default function RerunModal({ isOpen, onClose, runLog, onRerun }) {
    return (
        <Modal
            size={"medium"}
            title={"Replay entire Workflow"}
            isOpen={isOpen}
            onClose={() => onClose(false)}
        >
            <h3 className="py-3">Replaying this 1 run creates a new run on the latest published version.</h3>

            <div className="mt-5 sm:mt-6 flex space-x-2 sm:block">
                <ButtonV2
                    label={"Replay"}
                    type={CoreButtonTypes.primary}
                    onClick={() => {
                        onRerun(runLog)
                        onClose(false)
                    }}
                />
                <ButtonV2
                    label={"Close"}
                    type={CoreButtonTypes.white}
                    onClick={() => onClose(false)}
                />
            </div>
        </Modal>
    )
}